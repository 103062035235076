import React from "react";
import Field from "./Field";
import Dropdown from "./Dropdown";
import { COUNTRY_CODES, STATES } from "../../constants";

export default (props) => {
  const { shippingInfo, isBilling } = props;
  return (
    <>
      {!isBilling && (
        <>
          <h2 className="subtitle has-text-centered">Contact information</h2>
          <div className="shipping-form FormGroup">
            <Field
              label="Email"
              type="text"
              name="email"
              value={shippingInfo.email}
              onChange={(e) => {
                props.update("email", e.target.value);
              }}
            />
          </div>
        </>
      )}
      <h2 className="subtitle has-text-centered">{`${
        isBilling ? "Billing" : "Shipping"
      } information`}</h2>
      <div className="shipping-form FormGroup">
        <Dropdown
          label="Country"
          type="text"
          name="countryCode"
          value={shippingInfo.country}
          className="country-select-dropdown"
          defaultValue={"US"}
          onChange={(e) => {
            props.update("country", e.target.value);
          }}
        >
          <option key="United States" value="US">
            United States
          </option>
          <option disabled>---</option>
          {Object.keys(COUNTRY_CODES).map((countryName) => (
            <option key={countryName} value={COUNTRY_CODES[countryName]}>
              {countryName}
            </option>
          ))}
        </Dropdown>
        <div style={{ display: "flex" }}>
          <Field
            label="First Name"
            type="text"
            name="firstName"
            value={shippingInfo.firstName}
            onChange={(e) => {
              props.update("firstName", e.target.value);
            }}
          />
          <Field
            label="Last Name"
            type="text"
            name="lastName"
            value={shippingInfo.lastName}
            onChange={(e) => {
              props.update("lastName", e.target.value);
            }}
          />
        </div>
        <Field
          label="Address"
          type="text"
          name="address1"
          value={shippingInfo.address}
          onChange={(e) => {
            props.update("address", e.target.value);
          }}
        />
        <Field
          label="Address 2"
          type="text"
          name="address2"
          value={shippingInfo.address2}
          onChange={(e) => {
            props.update("address2", e.target.value);
          }}
        />
        <div style={{ display: "flex" }}>
          <Field
            label="City"
            type="text"
            name="city"
            style={{ borderBottom: "none" }}
            value={shippingInfo.city}
            onChange={(e) => {
              props.update("city", e.target.value);
            }}
          />

          {shippingInfo.country == "US" ? (
            <Dropdown
              label="State"
              type="text"
              name="countryCode"
              value={shippingInfo.state}
              className="state-select-dropdown"
              onChange={(e) => {
                props.update("state", e.target.value);
              }}
            >
              <option disabled hidden value={""}>
                Select state
              </option>
              {Object.keys(STATES).map((stateName) => (
                <option key={stateName} value={STATES[stateName]}>
                  {stateName}
                </option>
              ))}
            </Dropdown>
          ) : (
            <Field
              label="State/Province"
              type="text"
              name="state"
              style={{ borderBottom: "none" }}
              value={shippingInfo.state}
              onChange={(e) => {
                props.update("state", e.target.value);
              }}
            />
          )}
          <Field
            label="Zip Code"
            type="text"
            name="postalCode"
            style={{ borderBottom: "none" }}
            value={shippingInfo.zip}
            onChange={(e) => {
              props.update("zip", e.target.value);
            }}
          />
        </div>
        <Field
          label="Phone"
          type="text"
          name="phone"
          value={shippingInfo.phone}
          onChange={(e) => {
            props.update("phone", e.target.value);
          }}
        />
      </div>
    </>
  );
};
