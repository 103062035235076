import React from "react";
import SizingInfo from "../components/SizingInfo";

export default (props) => {
  return (
    <div id="story" className="hero is-large">
      <div className="">
        <div className="container">
          <h1 className="title is-size-5" style={{ padding: "2rem" }}>
            Sizing Guide
          </h1>
          <div className="has-text-centered" style={{ margin: "1rem" }}>
            <SizingInfo />
          </div>

          <div
            className="buttons is-centered"
            style={{ paddingBottom: "4rem" }}
          >
            <button
              className="button is-large is-light primary-button"
              onClick={() => {
                window.location = "/collections";
              }}
            >
              Continue Shopping
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
