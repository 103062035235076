import React from 'react';
import ProductPicker from './ProductPicker';

export default (props) => {
  const {
    setAvailabilityModalVisible, 
    setValue,
    item
  } = props

  return (
    <div className='modal is-active'>
      <div className="modal-background" onClick={() => setAvailabilityModalVisible(false)}></div>
      <div className="modal-content">
          <div className='box'>
            <ProductPicker url={item.url} products={item.available_products || []} setValue={setValue}/>
          </div>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={() => setAvailabilityModalVisible(false)}></button>
  </div>
  )
}