import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentSection from "./PaymentSection";
import ShippingForm from "./ShippingForm";
const stripePromise = loadStripe("pk_live_EoXgDpRzu5jJpmjO7rcyhcch008GraWc0x");
// process.env.REACT_APP_PAYMENT_ENV === "local"
//   ? loadStripe("pk_test_M3A6TKyQCSjBdDRGErzbi8Su00y4NBAcpL")
//   : loadStripe("pk_live_EoXgDpRzu5jJpmjO7rcyhcch008GraWc0x");

export default (props) => {
  return (
    <div className="billing-form">
      <Elements
        stripe={stripePromise}
        CustomFontSource={{
          family: "Roboto",
          src: "url(http://fonts.googleapis.com/css?family=Roboto)",
          weight: "500",
        }}
      >
        {!props.isBillingSameAsShipping && (
          <ShippingForm
            isBilling
            shippingInfo={props.billingInfo}
            update={props.update}
          />
        )}
        <label className="checkbox">
          <input
            type="checkbox"
            checked={props.isBillingSameAsShipping}
            onChange={(e) => props.setIsBillingSameAsShipping(e.target.checked)}
          />
          {` Use shipping address as billing address`}
        </label>
        <PaymentSection
          grandTotal={props.grandTotal}
          shippingFee={props.shippingFee}
          taxRate={props.taxRate}
          didProcessStripePayment={props.didProcessStripePayment}
        />
      </Elements>
    </div>
  );
};
