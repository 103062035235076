import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faStar, faTimes } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

const ReviewItem = ({ data }) => {
  const { title, description, rating, authorName } = data;
  return (
    <div
      className="divider"
      style={{ marginBottom: "1rem", paddingBottom: "0.5rem" }}
    >
      <div style={{ display: "flex", paddingBottom: "0.5rem" }}>
        {new Array(rating).fill(null).map((el) => (
          <FontAwesomeIcon icon={faStar} />
        ))}
      </div>
      <p className="is-size-7">{authorName}</p>
      <p className="is-size-4">{title}</p>
      <p className="is-size-6">{description}</p>
    </div>
  );
};

const ReviewsModal = ({ reviews, setModalVisible, modalVisible }) => {
  return (
    <div className={`modal cart-modal ${modalVisible ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onClick={() => {
          setModalVisible(false);
        }}
      ></div>
      <div className="modal-content cart-modal-content fullheight-overlay slide-right">
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 15,
            zIndex: 100,
            height: 30,
            width: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => setModalVisible(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="cart-modal-box" id="detailedCart">
          <h2
            className="subtitle is-uppercase"
            style={{ marginBottom: "1.5rem" }}
          >
            Reviews
          </h2>

          {!reviews.length && (
            <h2 className="subtitle has-text-centered">
              No reviews for this item yet
            </h2>
          )}

          <div className="cart-items-wrapper">
            {reviews.map((reviewItem, index) => (
              <ReviewItem key={"review-" + index} data={reviewItem} />
            ))}
          </div>

          <div className="buttons is-centered" style={{ marginTop: "1rem" }}>
            <button
              className="button is-black"
              onClick={() => setModalVisible(false)}
            >
              Continue shopping
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsModal;
