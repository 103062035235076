import React, { useState } from "react";
import axios from "axios";

export default ({ isActive, setModalVisible }) => {
  const [email, setEmail] = useState("");
  const [refEmail, setRefEmail] = useState("");
  const [buttonText, setButtonText] = useState("Give $20");

  const sendReferralRequest = async () => {
    await axios.post("/referral-promo", { email, refEmail });
    setButtonText("Gift card has been sent!");
    setTimeout(() => {
      setModalVisible(false);
    }, 5000);
  };

  const emailValid = (email) => {
    var mailformat =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    if (!email.match(mailformat)) {
      return false;
    }
    return true;
  };

  const formValid = () => {
    if (emailValid(email) && emailValid(refEmail) && email !== refEmail)
      return true;
    return false;
  };

  return (
    <div className={`modal ${isActive ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onClick={() => {
          setModalVisible(false);
        }}
      ></div>
      <div
        className="modal-content"
        style={{ maxHeight: "calc(100vh - 50px)" }}
      >
        <div className="box" style={{ padding: "3rem" }}>
          <h2 className="subtitle has-text-centered is-uppercase">
            Give $20 Get $20
          </h2>
          <p
            style={{
              textAlign: "center",
              padding: "10px",
              letterSpacing: "1px",
            }}
          >
            Join our friend referral program by giving your friend a $20
            discount towards their next lingerie set.
          </p>
          <p
            style={{
              textAlign: "center",
              padding: "10px",
              letterSpacing: "1px",
            }}
          >
            You will receive your $20 discount once they complete their
            purchase.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <input
              style={{ margin: 10, maxWidth: "20rem", minWidth: 250 }}
              className="input is-small dark-bg"
              placeholder="Friend's email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              style={{ margin: 10, maxWidth: "20rem", minWidth: 250 }}
              className="input is-small dark-bg"
              placeholder="Your email"
              type="text"
              value={refEmail}
              onChange={(e) => setRefEmail(e.target.value)}
            />
          </div>
          <div style={{ margin: 10 }} className="buttons is-centered">
            <button
              disabled={!formValid()}
              className="button is-primary"
              onClick={() => sendReferralRequest()}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={() => setModalVisible(false)}
      ></button>
    </div>
  );
};
