import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

const DetailsPart = function ({
  itemKey,
  title,
  text,
  handleRemove,
  handleChange,
}) {
  return (
    <li className="details-part-container">
      <input
        type="text"
        className="input"
        value={title}
        onChange={(e) => handleChange(itemKey, e.target.value, "title")}
      />
      <textarea
        className="textarea"
        value={text}
        onChange={(e) => handleChange(itemKey, e.target.value, "text")}
      ></textarea>
      <button
        className="button is-danger"
        onClick={() => handleRemove(itemKey)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </li>
  );
};

export default (props) => {
  const [list, setList] = useState([]);
  const [newFieldName, setNewFieldName] = useState("");

  useEffect(() => {
    if (props.values) {
      setList(props.values);
    }
  }, [props.values]);

  const handleChange = (key, value, field) => {
    let updatedList = [...list];
    let item = updatedList.find((el) => el.key === key);
    item[field] = value;
    props.updateDetails(updatedList);
  };

  const handleRemove = (key) => {
    props.updateDetails(list.filter((el) => el.key !== key));
  };

  const addField = (title) => {
    let camelKey = _.camelCase(title);
    setNewFieldName("");
    setList([...list, { key: camelKey, title: title, text: "" }]);
  };

  return (
    <div>
      <ul>
        {list.map((el) => (
          <DetailsPart
            {...el}
            itemKey={el.key}
            handleChange={handleChange}
            handleRemove={handleRemove}
          />
        ))}
      </ul>
      <div className="buttons is-centered new-desc-form">
        <input
          className="input"
          value={newFieldName}
          placeholder="Title"
          onChange={(e) => setNewFieldName(e.target.value)}
        />
        <button
          className="button is-success is-marginless"
          onClick={() => addField(newFieldName)}
        >
          Add Detail
        </button>
      </div>
    </div>
  );
};
