import React from 'react'

export default (props) => {
    const { title, subtitle } = props.data
    return (
        <div className='text-data-layout'>
            <div className='columns'>
                <div className='column'>
                    <h1 className='title'>{title}</h1>
                </div>
                <div className='column'>
                    <h2 className='subtitle'>{subtitle}</h2>
                </div>
            </div>
        </div>
    )
}