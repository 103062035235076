import React from "react";
import { useState, useEffect } from "react";

export default ({ displayPromo, displayGive20 }) => {
  const [currentAnnouncementIndex, setCurrentAnnouncementIndex] = useState(0);

  const announcements = [
    "Free US shipping on orders over $100 and easy returns",
    <>
      <a
        style={{ color: "white", textDecoration: "underline" }}
        onClick={() => displayPromo()}
      >
        Subscribe
      </a>
      &nbsp;to our newsletter and get 15% off
    </>,
    <>
      <a
        style={{ color: "white", textDecoration: "underline" }}
        onClick={() => displayGive20()}
      >
        Give $20, get $20
      </a>
    </>,
  ];

  setTimeout(() => {
    if (currentAnnouncementIndex < announcements.length - 1) {
      setCurrentAnnouncementIndex(currentAnnouncementIndex + 1);
    } else {
      setCurrentAnnouncementIndex(0);
    }
  }, 3000);

  return (
    <>
      <div id="announcement-bar" className="announcement-bar">
        {announcements[currentAnnouncementIndex]}
      </div>
    </>
  );
};
