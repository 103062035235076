import React, { useEffect, useState } from 'react';
import axios from 'axios'
import LandingControls from '../components/Admin/LandingControls'
import NewTileForm from '../components/Admin/NewTileForm'
import components from '../components/ProductLandingContent/components'

export default (props) => {
    const [data, setData] = useState({})
    const [message, setMessage] = useState('')
    const [newTileFormVisible, setNewTileFormVisible] = useState(false)

    const layoutMap = (landing) => {
        if (!landing) return
        return landing.map( (layout, index) => {
            const Layout = components[layout.layout].component;
            return (
                <div className='edit-landing-container'>
                    <Layout data={layout.data} />
                    <LandingControls index={index} data={layout} actions={{moveUp, moveDown, remove, edit}}/>
                </div>
            )
        })
    }

    const edit = (index, content) => {
        let landing = data.landing
        landing.splice(index, 1, content);
        setData({...data, landing})
        setMessage('')
    }

    const moveUp = (index) => {
        let landing = data.landing
        if (index > 0) {
            landing.splice(index - 1, 0, landing.splice(index, 1)[0]);
        }
        setData({...data, landing})
        setMessage('')
    }

    const moveDown = (index) => {
        let landing = data.landing
        if (index < landing.length - 1) {
            landing.splice(index + 1, 0, landing.splice(index, 1)[0]);
        }
        setData({...data, landing})
        setMessage('')
    }

    const remove = (index) => {
        let landing = data.landing
        landing.splice(index, 1)
        setData({...data, landing})
        setMessage('')
    }

    const saveChanges = async () => {
        let res = await axios.post('/admin/api/editItem', {
            _id: data._id,
            key: 'landing', 
            value: data.landing
        }).then(res => res.data)
        if (res.ok) {
            setMessage('Successfully Updated Item')
        }
        getProduct()
    }

    const addNewTile = (content) => {
        let landing = data.landing
        landing.push(content)
        setData({...data, landing})
        setMessage('')
        setNewTileFormVisible(false)
    }

    const getProduct = async () => {
        let data = await axios.get(`/product`, { params: { url: props.match.params[0]} }).then(res => res.data)
        setData(data)
    }

    useEffect(()=>{
        getProduct()
    }, [])
 
    return ( 
        <div id='editLanding'>
            <div className='hero is-fullheight'>
                <div className='hero-body'>
                    <div className='container'>
                        <div id='productLanding' >
                            { layoutMap(data.landing) }
                            { newTileFormVisible
                                ? <NewTileForm submit={addNewTile}/>
                                : null
                            }
                            {
                                message === ''
                                    ? null
                                    : <h2 className='title has-text-success'>{message}</h2>
                            }
                            <button onClick={()=>{ setNewTileFormVisible(!newTileFormVisible) }} className='button is-success is-rounded is-large add-tile'>+</button>
                            <button onClick={()=>{ saveChanges() }} className='button is-primary is-large'>SAVE</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}