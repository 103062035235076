import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import ReturnConfirmationModal from "../components/ReturnConfirmationModal";

export default (props) => {
    const search = useLocation().search;
    const name = new URLSearchParams(search).get("orderId");

    const [orderId, setOrderId] = useState(name || "");
    const [viewError, setViewError] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const [requestedReturns, setRequestedReturns] = useState([]);
    const [shouldShowModal, setShouldShowModal] = useState(false);
    const [creditSelected, setCreditSelected] = useState(false);
    const [isOrderInternational, setIsOrderInternational] = useState(false)

    const getOrderDetails = async () => {
        const res = await axios.post("/orderDetailsById", { orderId });
        if (res.data.status == "fail") {
            setViewError(true);
        } else {
            setViewError(false);
            if (!['us', 'usa', 'united states', 'united states of america'].includes(res.data.country.toLowerCase())) {
                setIsOrderInternational(true)
            }
            setOrderData(res.data.orderData);
        }
    };

    const checkItem = (index) => {
        if (requestedReturns.includes(index)) {
            setRequestedReturns([
                ...requestedReturns.filter((el) => el !== index),
            ]);
        } else {
            setRequestedReturns([...requestedReturns, index]);
        }
    };

    useEffect(() => {
        if (name) {
            getOrderDetails();
        }
    }, []);

    const requestReturn = async () => {
        await axios.post("/requestReturn", {
            creditSelected,
            orderId,
            items: orderData.filter((el, index) =>
                requestedReturns.includes(index)
            ),
            international: isOrderInternational
        });
        setOrderId("");
        setOrderData([]);
        setRequestedReturns([]);
        setShouldShowModal(true);
    };

    return (
        <div
            id='story'
            className='hero is-medium'
            style={{ minHeight: "calc(100vh - 390px)" }}>
            <ReturnConfirmationModal
                isActive={shouldShowModal}
                setModalVisible={setShouldShowModal}
            />
            <div className='hero-body'>
                <div id='cart-list' className='container'>
                    <h1 className='title' style={{ marginBottom: "0.75rem" }}>
                        Start a return
                    </h1>
                    <p style={{ padding: "1rem", textAlign: "center" }}>
                        We're sorry you're not happy with your purchase.
                        <br />
                        Let's locate your order to initiate a return.
                    </p>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                        }}>
                        <input
                            style={{ maxWidth: "23rem" }}
                            className='input'
                            type='number'
                            value={orderId}
                            onChange={(e) => setOrderId(e.target.value)}
                        />
                        <button
                            style={{ marginLeft: "0.5rem" }}
                            className='button is-primary'
                            disabled={orderId == ""}
                            onClick={() => {
                                getOrderDetails();
                            }}>
                            Search
                        </button>
                    </div>
                    {viewError && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                            }}>
                            <p style={{ padding: "1rem", textAlign: "center" }}>
                                Order not found...
                            </p>
                        </div>
                    )}
                    {orderData.length !== 0 && (
                        <p
                            style={{
                                paddingTop: "2rem",
                                textAlign: "center",
                                fontSize: "23px",
                            }}>
                            Select items you'd like to return
                        </p>
                    )}
                    {orderData.length !== 0 &&
                        orderData.map((el, index) => {
                            const {
                                product_type,
                                title,
                                size,
                                color,
                                url,
                                pictures,
                                available_products,
                            } = el;
                            const product = available_products.find(
                                (el) => el.type == product_type
                            );
                            return (
                                <div
                                    key={"caritem" + index}
                                    className='cart-elem'
                                    style={{
                                        maxWidth: "fit-content",
                                        margin: "1rem auto",
                                        padding: "0.5rem",
                                    }}>
                                    <div
                                        className='cart-list-title'
                                        onClick={() => {
                                            window.location = "/product/" + url;
                                        }}>
                                        <h1>
                                            {product_type} - {title}
                                        </h1>
                                    </div>
                                    <div className='cart-list-body'>
                                        <input
                                            type='checkbox'
                                            onClick={() => {
                                                checkItem(index);
                                            }}
                                            checked={requestedReturns.includes(
                                                index
                                            )}></input>
                                        <img
                                            src={
                                                pictures[0].slice(
                                                    0,
                                                    pictures[0].length - 4
                                                ) + "-preview.jpg"
                                            }
                                            width={"15%"}
                                            className='picture'
                                        />
                                        <div className='size'>size: {size}</div>
                                        <div className='color-container'>
                                            {color}
                                        </div>
                                        <div className='price'>
                                            ${product.price}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    {requestedReturns.length !== 0 && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                marginTop: "3rem",
                            }}>
                            <button
                                className='button is-primary is-medium'
                                disabled={orderId == ""}
                                onClick={() => {
                                    requestReturn();
                                }}>
                                Request Return
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
