import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import DetailedCart from './DetailedCart'

export default ({ setExpanded, updateCart }) => {

    useEffect(()=>{
        updateCart()
    }, [])

    return (
        <div className='cart-icon' onClick={()=>{ setExpanded(true) }}>
            <FontAwesomeIcon icon={faShoppingBag} color={'#000'}/>
        </div>
    )
}