import React, { useState, useEffect } from "react";
import axios from "axios";
import NewsletterCreator from "./NewsletterCreator";

export default function NewsletterManager() {
  const [newsletters, setNewsletters] = useState([]);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [selectedNewsletter, setSelectedNewsletter] = useState(null);
  const [testEmail, setTestEmail] = useState({});

  useEffect(() => {
    fetchNewsletters();
  }, []);

  const sendTestEmail = async (id) => {
    await axios.post("/admin/api/sendTestNewsletter", {
      id,
      email: testEmail[id],
    });
    alert("Test email sent!");
  };

  const fetchNewsletters = async () => {
    const res = await axios.get("/admin/api/getNewsletters");
    setNewsletters(res.data);
  };

  const cancelNewsletter = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to permanently delete this email?"
    );
    if (confirmed) {
      await axios.post("/admin/api/cancelNewsletter", { id });
      setNewsletters(newsletters.filter((nl) => nl._id !== id));
    }
  };

  const handleSave = () => {
    setSelectedNewsletter(null);
    fetchNewsletters();
  };

  return (
    <div>
      <h2>Manage Newsletters</h2>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "2rem",
        }}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Subject
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Status
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Scheduled At
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Test email
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {newsletters.map((nl) => (
            <tr key={nl._id}>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {nl.subject}
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                <span
                  className={`tag is-rounded ${
                    nl.status === "Draft"
                      ? "is-warning"
                      : nl.status === "Approved"
                      ? "is-success"
                      : "is-info"
                  }`}
                >
                  {nl.status}
                </span>
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {new Date(nl.scheduledAt).toLocaleString()}
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                <input
                  type="email"
                  placeholder="Test email"
                  value={testEmail[nl._id]}
                  onChange={(e) => setTestEmail({ [nl._id]: e.target.value })}
                />
                <button onClick={() => sendTestEmail(nl._id)}>Send Test</button>
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                <button onClick={() => setSelectedNewsletter(nl)}>Edit</button>

                <button onClick={() => cancelNewsletter(nl._id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        onClick={() => setIsCreatingNew(true)}
        className="button is-rounded"
        style={{ marginBottom: "1rem" }}
      >
        Create New Newsletter
      </button>
      {(isCreatingNew || selectedNewsletter) && (
        <NewsletterCreator
          selectedNewsletter={selectedNewsletter}
          onSave={handleSave}
        />
      )}
    </div>
  );
}
