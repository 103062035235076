import React, {useState, useEffect} from 'react';
import axios from 'axios'

export default (props) => {
    const defaultItem = { code: '', discount: '', existing: false, password: '', payout: '', isDollarAmount: false }
    const [data, setData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [currentItem, setCurrentItem] = useState(defaultItem)

    const loadData = () => {
        axios.get('/admin/api/getAllPromotions').then(res => setData(res.data))
        setShowModal(false)
    }

    const submitEdit = async () => {
        if (currentItem.existing) {
            await axios.post('/admin/api/updatePromotions', currentItem)
        } else {
            await axios.post('/admin/api/createPromotions', currentItem)
        }
        loadData()
    }

    const submitDelete = async () => {
        await axios.post('/admin/api/deletePromotions', currentItem)
        loadData()
    }

    useEffect(()=>{
        loadData()
    }, [])

    return (
        <div id='promo-admin' className='promo-editor'>
            <div className={`modal ${showModal ? 'is-active' : ''}`}>
                <div className="modal-background" onClick={() => setShowModal(false)}></div>
                <div className="modal-content" style={{ height: '23rem' }}>
                    <div className='box'>
                        <input disabled={currentItem.existing} placeholder='Code' className='input' value={currentItem.code} onChange={e => setCurrentItem({...currentItem, code: e.target.value})}/>
                        <div style={{ display: 'flex'}}>
                            <input disabled={currentItem.existing} type='checkbox' checked={currentItem.isDollarAmount} onClick={(e) => setCurrentItem({...currentItem, isDollarAmount: !currentItem.isDollarAmount})}/>
                            <p>Dollar amount discount</p>
                        </div>
                        <input placeholder={currentItem.isDollarAmount ? 'Dollar value $' : 'Discount Rate (0.9 = 10% discount)'} className='input' value={currentItem.discount} onChange={e => setCurrentItem({...currentItem, discount: e.target.value})}/>
                        <input placeholder='Performance access pass' className='input' value={currentItem.password} onChange={e => setCurrentItem({...currentItem, password: e.target.value})}/>
                        <input placeholder='Payout' className='input' value={currentItem.payout} onChange={e => setCurrentItem({...currentItem, payout: e.target.value})}/>
                        <div className='buttons'>
                            <button className='button is-success' onClick={()=>{ submitEdit() }}>Submit</button>
                            <button className={`button is-danger ${currentItem.existing ? '' : 'is-hidden'}`} onClick={()=>{ submitDelete() }}>Delete</button>
                        </div>
                    </div>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={() => setShowModal(false)}></button>
            </div>
            { 
                data.map(el => <h1 className='title editable-promo' onClick={()=>{setCurrentItem({...el, existing: true}); setShowModal(true)}}>{el.code + ' ' + (el.type && el.type == 'dollarAmount' ? '$' + el.discount : el.discount * 100 - 100 + '%')}</h1>)
            }
            <button className='button is-success' onClick={()=>{setCurrentItem(defaultItem); setShowModal(true)}}>Create New</button>
        </div>
    )

}
