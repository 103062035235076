import React from "react";
import Form from "react-bootstrap/Form";

export default ({
  label,
  type,
  placeholder,
  value,
  onChange,
  name,
  className,
  defaultValue,
  children,
}) => (
  <>
    <Form.Select
      onChange={onChange}
      value={value}
      type={type}
      name={name}
      defaultValue={defaultValue}
      className={className}
    >
      {children}
    </Form.Select>
  </>
);
