import React, { useState, useEffect, useCallback } from "react";

import axios from "axios";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default (props) => {
  const defaultSize = {
    name: "",
    sold_out: false,
  };
  const { url } = props;
  const [sizes, setSizes] = useState(props.sizes);
  const [currentSize, setCurrentSize] = useState(defaultSize);
  const [showModal, setShowModal] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [showAllSubs, setShowAllSubs] = useState(false);
  const [showItemSubs, setShowItemSubs] = useState(false);
  const [subFilterString, setSubFilterString] = useState("");
  const [message, setMessage] = useState();

  const getItemSubscriptions = async (sizeName) => {
    const res = await axios.post("/admin/api/getItemSubscriptions", {
      url,
      sizeName,
    });
    setSubscribers(res.data.filteredSubscriptions);
    setAllSubscriptions(res.data.allSubscriptions);
  };

  useEffect(() => {
    setSizes(props.sizes);
  }, [props.sizes]);

  const submitEdit = () => {
    let { name, sold_out, index } = currentSize;
    let temp = [...sizes];
    if (typeof index !== "undefined") {
      temp.splice(index, 1, { name, sold_out: sold_out ? true : false });
    } else {
      temp.push({ name, sold_out: sold_out ? true : false });
    }
    props.setValue(temp);
    setSizes(temp);
    setShowModal(false);
  };

  const submitDelete = () => {
    let { index } = currentSize;
    if (typeof index !== "undefined") {
      let temp = [...sizes];
      temp.splice(index, 1);
      props.setValue(temp);
      setSizes(temp);
    }
    setShowModal(false);
  };

  const editSize = (size, index) => {
    getItemSubscriptions(size.name);
    setCurrentSize({ ...size, index });
    setShowModal(true);
  };

  const sendBackInStockNotification = async (emailList) => {
    let data = await axios
      .get(`/product`, { params: { url } })
      .then((res) => res.data);

    setSubFilterString("");
    setSelectedEmails([]);

    setMessage({ type: "SUCCESS", message: "Successfuly sent notification" });

    await Promise.all(
      emailList.map(async (email) => {
        await axios.post("/admin/api/sendBackInStockNotification", {
          url,
          email: email,
          picture: data.pictures[0],
          size: currentSize.name,
          product_type: "",
          product_name: data.title,
          color: "",
          price: data.available_products[0].price,
        });
      })
    );
  };

  const handleSelectEmail = useCallback(
    (email) => {
      if (selectedEmails.includes(email)) {
        setSelectedEmails(selectedEmails.filter((el) => el != email));
      } else {
        setSelectedEmails([...selectedEmails, email]);
      }
    },
    [selectedEmails, setSelectedEmails]
  );

  useEffect(() => {
    if (subFilterString !== "") {
      setShowAllSubs(true);
      setShowItemSubs(true);
    } else {
      setShowAllSubs(false);
      setShowItemSubs(false);
    }
  }, [subFilterString]);

  return (
    <div>
      <div className={`modal ${showModal ? "is-active" : ""}`}>
        <div
          className="modal-background"
          onClick={() => setShowModal(false)}
        ></div>
        <div className="modal-content">
          <div className="box">
            <input
              placeholder="Size Name"
              className="input"
              value={currentSize.name}
              onChange={(e) =>
                setCurrentSize({ ...currentSize, name: e.target.value })
              }
            />
            <label className="checkbox">
              <input
                type="checkbox"
                checked={currentSize.sold_out}
                onChange={(e) => {
                  setCurrentSize({
                    ...currentSize,
                    sold_out: !currentSize.sold_out,
                  });
                }}
              />
              Sold Out
            </label>
            <div className="buttons">
              <button
                className="button is-light"
                onClick={() => {
                  submitDelete();
                }}
              >
                Delete
              </button>
              <button
                className="button is-success"
                onClick={() => {
                  submitEdit();
                }}
              >
                Save
              </button>
            </div>
            <input
              placeholder="Filter emails"
              className="input"
              value={subFilterString}
              onChange={(e) => setSubFilterString(e.target.value)}
            />
            <p onClick={() => setShowItemSubs(!showItemSubs)}>
              <FontAwesomeIcon
                style={{ paddingTop: "0.5rem" }}
                icon={showItemSubs ? faAngleDown : faAngleRight}
              />
              <strong>Item Subsciptions</strong>
            </p>
            {showItemSubs &&
              subscribers
                .filter(
                  (el) =>
                    subFilterString == "" || el.email.includes(subFilterString)
                )
                .map((sub) => (
                  <div>
                    <input
                      type="checkbox"
                      id="scales"
                      name="scales"
                      checked={selectedEmails.includes(sub.email)}
                      onClick={() => handleSelectEmail(sub.email)}
                    />
                    <label for="scales">
                      {sub.email} - Size: {sub.requestedNotifications[0].size}
                    </label>
                  </div>
                ))}
            <p onClick={() => setShowAllSubs(!showAllSubs)}>
              <FontAwesomeIcon
                style={{ paddingTop: "0.5rem" }}
                icon={showAllSubs ? faAngleDown : faAngleRight}
              />
              <strong>All Subsciptions</strong>
            </p>
            {showAllSubs &&
              allSubscriptions
                .filter(
                  (el) =>
                    subFilterString == "" || el.email.includes(subFilterString)
                )
                .map((sub) => (
                  <div>
                    <input
                      type="checkbox"
                      id="scales"
                      name="scales"
                      checked={selectedEmails.includes(sub.email)}
                      onClick={() => handleSelectEmail(sub.email)}
                    />
                    <label for="scales">{sub.email}</label>
                  </div>
                ))}
            <button
              className="button is-info"
              onClick={() => {
                sendBackInStockNotification(selectedEmails);
              }}
              disabled={currentSize.sold_out || !selectedEmails.length}
            >
              Send Back In Stock Notification
            </button>
            <div style={{ margin: "1rem" }}>
              {!!message && (
                <article class="message is-success">
                  <div class="message-header">
                    <p>Success</p>
                    <button
                      onClick={() => setMessage()}
                      class="delete"
                      aria-label="delete"
                    ></button>
                  </div>
                  <div class="message-body">{message.message}</div>
                </article>
              )}
            </div>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setShowModal(false)}
        ></button>
      </div>
      Sizes:
      <div className="size-container-wrap">
        {sizes.length
          ? sizes.map((size, i) => (
              <div
                className={`size ${size.sold_out ? "is-sold-out" : ""}`}
                onClick={() => {
                  editSize(size, i);
                }}
              >
                {size.name}
              </div>
            ))
          : "No sizes"}
      </div>
      <button
        className="button is-success"
        onClick={() => {
          setCurrentSize(defaultSize);
          setShowModal(true);
        }}
      >
        Add Size
      </button>
    </div>
  );
};
