import React, { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const uploadFile = async (file) => {
    if (!file) return;
    const formData = new FormData();
    formData.append("file", file, file.name);
    setIsLoading(true);
    try {
      const location = await axios
        .post("/uploadPicture", formData)
        .then((res) => res.data);
      setIsLoading(false);
      return location;
    } catch (e) {
      alert(e);
    }
  };

  const onFilesAdded = async (evt) => {
    let location = await uploadFile(evt.target.files[0]);
    if (!location) return;
    props.updateValue(location);
  };

  return (
    <div className="form image-upload">
      <input
        className="input-file is-loading"
        type="file"
        onChange={(e) => onFilesAdded(e)}
      />
      {isLoading ? (
        <FontAwesomeIcon
          className="fa-spin fa-2xl has-text-centered"
          icon={faSpinner}
        />
      ) : (
        <p>Upload File</p>
      )}
    </div>
  );
};
