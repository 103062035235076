import React, { useEffect, useState } from "react";
import Axios from "axios";
import Select from "react-select";
import ReviewManagementPanel from "./ReviewManagementPanel";

export default (props) => {
  const { setRelatedItemsModalVisible, setValue, item } = props;

  const [suggestions, setSuggestions] = useState([]);
  const [relatedItems, setRelatedItems] = useState(
    item.related_items || {
      completeSet: [],
      similarItems: [],
    }
  );

  const getExistingItems = async () => {
    let existing = await Axios.get("/admin/api/existingItems").then(
      (res) => res.data
    );
    setSuggestions(
      existing.map((item) => ({
        label: item.title,
        value: item._id,
      }))
    );
  };

  useEffect(() => {
    getExistingItems();
  }, []);

  return (
    <div className="modal is-active">
      <div
        className="modal-background"
        onClick={() => setRelatedItemsModalVisible(false)}
      ></div>
      <div style={{ zIndex: 3 }}>
        <section class="hero">
          <div class="hero-body">
            <div className="box">
              <p class="title">Related items</p>
              <p class="subtitle">Complete the set</p>
              <Select
                onChange={(opt) =>
                  setRelatedItems({ ...relatedItems, completeSet: opt })
                }
                value={relatedItems.completeSet}
                isMulti
                options={suggestions}
              />
              <p class="subtitle" style={{ marginTop: "1rem" }}>
                You may also like
              </p>
              <Select
                onChange={(opt) =>
                  setRelatedItems({ ...relatedItems, similarItems: opt })
                }
                value={relatedItems.similarItems}
                isMulti
                options={suggestions}
              />
              <button
                className="button is-success"
                onClick={() => {
                  setValue("related_items", relatedItems);
                  setRelatedItemsModalVisible(false);
                }}
              >
                Save product
              </button>
              <ReviewManagementPanel productId={item._id} />
            </div>
          </div>
        </section>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={() => setRelatedItemsModalVisible(false)}
      ></button>
    </div>
  );
};
