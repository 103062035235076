import React from "react";

export default (props) => (
  <div className="product-placeholder">
    <div className="hero">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered top-row-container">
            <div className="column image-column">
              <div className="image-container"></div>
            </div>
            <div className="column product-data-wrapper">
              <div className="title"></div>
              <div className="price"></div>
              <div className="sizing-guide"></div>
              <div className="size-selector-container">
                <div className="size-option-wrapper">
                  <div className="size-option selected"></div>
                </div>
                <div className="size-option-wrapper">
                  <div className="size-option"></div>
                </div>
                <div className="size-option-wrapper">
                  <div className="size-option"></div>
                </div>
                <div className="size-option-wrapper">
                  <div className="size-option"></div>
                </div>
              </div>
              <button className="button is-dark is-large add-to-cart"></button>
              <ul>
                <li className={`desc-block-container is-active`}>
                  <div className={`subtitle description-title is-active`}></div>
                  <div className={`subtitle description`}></div>
                </li>
                <li className={`desc-block-container`}>
                  <div className={`subtitle description-title `}></div>
                </li>
                <li className={`desc-block-container`}>
                  <div className={`subtitle description-title `}></div>
                </li>
              </ul>
              <ul style={{ marginTop: "5rem" }}>
                <li className={`desc-block-container is-active`}>
                  <div className={`subtitle description-title is-active`}></div>
                  <div className={`subtitle description`}></div>
                </li>
                <li className={`desc-block-container`}>
                  <div className={`subtitle description-title `}></div>
                </li>
                <li className={`desc-block-container`}>
                  <div className={`subtitle description-title `}></div>
                </li>
              </ul>
              <ul style={{ marginTop: "5rem" }}>
                <li className={`desc-block-container is-active`}>
                  <div className={`subtitle description-title is-active`}></div>
                  <div className={`subtitle description`}></div>
                </li>
                <li className={`desc-block-container`}>
                  <div className={`subtitle description-title `}></div>
                </li>
                <li className={`desc-block-container`}>
                  <div className={`subtitle description-title `}></div>
                </li>
              </ul>
              <ul style={{ marginTop: "5rem" }}>
                <li className={`desc-block-container is-active`}>
                  <div className={`subtitle description-title is-active`}></div>
                  <div className={`subtitle description`}></div>
                </li>
                <li className={`desc-block-container`}>
                  <div className={`subtitle description-title `}></div>
                </li>
                <li className={`desc-block-container`}>
                  <div className={`subtitle description-title `}></div>
                </li>
              </ul>
              <ul style={{ marginTop: "5rem" }}>
                <li className={`desc-block-container is-active`}>
                  <div className={`subtitle description-title is-active`}></div>
                  <div className={`subtitle description`}></div>
                </li>
                <li className={`desc-block-container`}>
                  <div className={`subtitle description-title `}></div>
                </li>
                <li className={`desc-block-container`}>
                  <div className={`subtitle description-title `}></div>
                </li>
              </ul>
              <ul style={{ marginTop: "5rem" }}>
                <li className={`desc-block-container is-active`}>
                  <div className={`subtitle description-title is-active`}></div>
                  <div className={`subtitle description`}></div>
                </li>
                <li className={`desc-block-container`}>
                  <div className={`subtitle description-title `}></div>
                </li>
                <li className={`desc-block-container`}>
                  <div className={`subtitle description-title `}></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
