import React, { useEffect, useState, useMemo, useCallback } from "react";
import CollapsableDescription from "../components/CollapsableDescription";
import ProductPlaceholder from "../components/ProductPlaceholder/ProductPlaceholder";
import axios from "axios";
import _ from "lodash";
import ProgressiveImage from "react-progressive-image";
import PurchasingOptions from "./PurchasingOptions";
import Collection from "../components/Collection";
import Video from "../components/Video/Video";
import Carousel from "react-gallery-carousel";

import "./index.css";

export default (props) => {
  const [data, setData] = useState({
    available_colors: [],
  });
  const [productLoaded, setProductLoaded] = useState(false);

  const getProduct = async () => {
    let dataRes = await axios
      .get(`/product`, { params: { url: props.match.params[0] } })
      .then((res) => res.data);
    setData(dataRes);
    setProductLoaded(true);
  };

  useEffect(() => {
    getProduct();
  }, []);

  const productCategory = useMemo(() => {
    return props.categories?.find((el) => el.key == data.category);
  }, [props.categories, data.category]);

  const navigateBack = useCallback(() => {
    if (productCategory) {
      window.location = `/categories/?category=${productCategory.key}`;
    } else {
      window.location = "/collections";
    }
  }, [productCategory]);

  const ProgLoaded = ({ src }) =>
    src.includes(".jpg") ? (
      <ProgressiveImage
        src={src}
        placeholder={src.slice(0, src.length - 4) + "-small.jpg"}
      >
        {(src) => <img className="main-image" src={src} />}
      </ProgressiveImage>
    ) : (
      <Video aspectRatio={"2:3"} src={src} />
    );

  const images = useMemo(() => {
    return (
      data.pictures && (
        <Carousel
          hasIndexBoard={false}
          hasMediaButton={false}
          canAutoPlay={false}
          children={data.pictures.map((el) => (
            <ProgLoaded src={el} />
          ))}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      )
    );
  }, [data, data.pictures]);

  const suggestedProducts = useMemo(
    () =>
      data.suggestedProducts?.length > 0 && (
        <>
          <div style={{ marginTop: "0.5rem", width: "100%" }}>
            <Collection
              addToCart={props.addToCart}
              className="collection-container-complete-set"
              data={[...data.suggestedProducts].slice(0, 4)}
            />
          </div>
        </>
      ),
    [data, data.suggestedProducts]
  );

  return (
    <div id="product">
      {!productLoaded ? (
        <ProductPlaceholder />
      ) : (
        <div>
          <div className="hero is-medium">
            <div className="hero-body" style={{ paddingTop: "1rem" }}>
              <div className="container">
                <button
                  className="button is-light back-button"
                  onClick={() => navigateBack()}
                >
                  Back To {productCategory?.title || "Collections"}
                </button>
                <div
                  style={{ margin: 0 }}
                  className="columns is-centered top-row-container"
                >
                  <div className="column image-column">
                    <div className="image-container">{images}</div>
                  </div>
                  <div className="column product-data-wrapper">
                    <h1 className="title">{data?.title}</h1>
                    {
                      <PurchasingOptions
                        setViewSizingModal={props.setIsSizingModalVisible}
                        addToCart={props.addToCart}
                        options={data.available_products}
                        pictures={data.pictures}
                        updateCart={props.updateCart}
                        url={props.match.params[0]}
                        is_final_sale={data?.is_final_sale}
                      />
                    }
                    <CollapsableDescription
                      data={data.description}
                      showReviews={() => props.showReviewsForProduct(data._id)}
                    />
                    {!!data.completeSet?.length && (
                      <div style={{ marginTop: "2rem", width: "100%" }}>
                        <p className="subtitle">Complete the look</p>
                        <Collection
                          addToCart={props.addToCart}
                          className="product-container-complete-set"
                          data={data.completeSet}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <p className="title has-text-centered marketing-list">
                  You may also like
                </p>
                <div className="similar-items-container">
                  {suggestedProducts}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
