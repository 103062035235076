import React from "react";

export default () => {
  return (
    <div>
      <h2 className="subtitle has-text-centered is-uppercase">
        Bra Sizing Chart
      </h2>
      <div
        className="scroll-bar-visible"
        style={{
          maxWidth: "100%",
          overflowX: "scroll",
          scrollbarWidth: "auto",
          margin: "2rem auto",
          fontSize: "0.7rem",
        }}
      >
        <table
          className="table is-striped is-narrow is-hoverable is-fullwidth is-bordered"
          style={{
            minWidth: "355px",
            maxWidth: "680px",
            margin: "0.5rem auto",
          }}
        >
          <thead>
            <th>Size</th>
            <th className="has-text-centered">XS</th>
            <th className="has-text-centered">S</th>
            <th className="has-text-centered">M</th>
            <th className="has-text-centered">L</th>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Cup Size</strong>
              </td>
              <td className="has-text-centered">
                <strong>
                  30A / 30B / 30C <br /> 32A / 32B / 34AA
                </strong>
              </td>
              <td className="has-text-centered">
                <strong>
                  30D / 32C / 32D <br /> 34A / 34B
                </strong>
              </td>
              <td className="has-text-centered">
                <strong>
                  32DD / 34C / 34D <br /> 36A / 36B
                </strong>
              </td>
              <td className="has-text-centered">
                <strong>
                  36C / 36D <br /> 38A / 38B / 38C
                </strong>
              </td>
            </tr>
            <tr>
              <td>Full Bust</td>
              <td className="has-text-centered">
                31 - 33.5" <br /> 78 - 85 cm
              </td>
              <td className="has-text-centered">
                33 - 35.5" <br /> 84 - 90 cm
              </td>
              <td className="has-text-centered">
                35 - 37" <br /> 88 - 94 cm
              </td>
              <td className="has-text-centered">
                37 - 40" <br /> 94 - 101 cm
              </td>
            </tr>
            <tr>
              <td>Under Bust</td>
              <td className="has-text-centered">
                26 - 28" <br /> 66 - 71 cm
              </td>
              <td className="has-text-centered">
                28 - 30" <br /> 71 - 76 cm
              </td>
              <td className="has-text-centered">
                30 - 32" <br /> 76 - 82 cm
              </td>
              <td className="has-text-centered">
                32 - 35" <br /> 82 - 90 cm
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2 className="subtitle has-text-centered is-uppercase">
        Panties Sizing Chart
      </h2>
      <div
        className="scroll-bar-visible"
        style={{
          maxWidth: "100%",
          overflowX: "scroll",
          scrollbarWidth: "auto",
          margin: "2rem auto",
          fontSize: "0.7rem",
        }}
      >
        <table
          className="table is-striped is-narrow is-hoverable is-fullwidth is-bordered"
          style={{
            minWidth: "355px",
            maxWidth: "680px",
            margin: "0.5rem auto",
          }}
        >
          <thead>
            <th>Size</th>
            <th className="has-text-centered">XS</th>
            <th className="has-text-centered">S</th>
            <th className="has-text-centered">M</th>
            <th className="has-text-centered">L</th>
          </thead>
          <tbody>
            <tr>
              <td>Hip</td>
              <td className="has-text-centered">
                33 - 36.5" <br /> 84 - 93 cm
              </td>
              <td className="has-text-centered">
                37 - 38.5" <br /> 94 - 98 cm
              </td>
              <td className="has-text-centered">
                39 - 40.5" <br /> 99 - 103 cm
              </td>
              <td className="has-text-centered">
                41 - 42.5" <br /> 104 - 108 cm
              </td>
            </tr>
            <tr>
              <td>Waist</td>
              <td className="has-text-centered">
                22 - 25.5" <br /> 56 - 65 cm
              </td>
              <td className="has-text-centered">
                26 - 27.5" <br /> 66 - 71 cm
              </td>
              <td className="has-text-centered">
                28 - 29.5" <br /> 72 - 76 cm
              </td>
              <td className="has-text-centered">
                30 - 31.5" <br /> 77 - 81 cm
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
