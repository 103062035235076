import React, { useState, useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

export default (props) => {
    const [data, setData] = useState([]);
    const [title, setCatTitle] = useState("");

    useEffect(() => {
        axios.get("/getCategories").then((res) => setData(res.data));
    }, []);

    const deleteCategory = async (key) => {
        await axios.post("/admin/api/deleteCategory", { key });
        axios.get("/getCategories").then((res) => setData(res.data));
    };

    const createCategory = async () => {
        const key = title.replace(/\s/g, "").toLowerCase();
        await axios.post("/admin/api/createCategory", { title, key });
        axios.get("/getCategories").then((res) => setData(res.data));
    };

    return (
        <div id='category-admin' className='promo-editor'>
            <div className='box'>
                <div className='title'>Categories</div>
                {data.map((el) => (
                    <div className='editable-category'>
                        <p>{el.title}</p>
                        <p>{el.key}</p>
                        <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faTimes}
                            onClick={() => deleteCategory(el.key)}
                        />
                    </div>
                ))}
                <div className='title'>Create new</div>

                <div>
                    <input
                        className='input is-light'
                        value={title}
                        onChange={(e) => setCatTitle(e.target.value)}
                    />
                    <button
                        disabled={title.replace(/\s/g, "") === ""}
                        className='button is-success primary-button'
                        onClick={() => createCategory()}>
                        Create
                    </button>
                </div>
            </div>
        </div>
    );
};
