import React, { useEffect } from "react";
import ProgressiveImage from "react-progressive-image";
import axios from "axios";

export default (props) => {
  useEffect(() => {
    if (props.match.params[0] && props.match.params[0]?.length === 24) {
      axios.post("/unsubscribe", {
        userId: props.match.params[0],
      });
    }
  });

  return (
    <div id="story" className="hero is-large">
      <div className="hero-body">
        <div className="container">
          <div className="has-text-centered">
            <ProgressiveImage
              src={
                "https://murashkilingerie.com/static/media/lingerie-logo@2x.f6a74878.png"
              }
              placeholder={
                "https://murashkilingerie.com/static/media/lingerie-logo@2x.f6a74878.png"
              }
            >
              {(src) => <img style={{ width: "150px" }} src={src} />}
            </ProgressiveImage>
            <h1 style={{ padding: "2rem" }}>Successfully unsubscribed</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
