import React from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

export default ({ label, type, placeholder, value, onChange, name, style }) => (
    <>
        <FloatingLabel
            // style={{ padding: "0.5rem" }}
            controlId='floatingInput'
            label={label}>
            <Form.Control
                onChange={onChange}
                value={value}
                type={type}
                name={name}
                placeholder={placeholder || "value"}
            />
        </FloatingLabel>
    </>
);
