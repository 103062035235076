import React, { useState, useEffect } from "react";
import ColorSelect from "../components/ColorSelect";
import SizeSelector from "../components/SizeSelector";
import GetNotifiedModal from "../components/GetNotifiedModal";
import axios from "axios";

export default ({
  url,
  options,
  addToCart,
  setViewSizingModal,
  pictures,
  is_final_sale,
}) => {
  const [shouldShowNotifModal, setShouldShowNotifModal] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [isFullySoldOut, setIsFullySoldOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSelections, setCurrentSelections] = useState(
    options.map((el) => ({
      product_type: el.type,
      product_name: el.name,
      sizeIndex: null,
      colorIndex: 0,
      color: el.colors[0]?.color_name,
      size: null,
      url,
      isSoldOut: false,
    }))
  );

  const itemsSelected = () => {
    let count = 0;
    let price = 0;
    for (let i = 0; i < options.length; i++) {
      if (currentSelections[i].size) {
        count++;
        price += Number(options[i].price);
      }
    }
    return { count, price };
  };

  useEffect(() => {
    if (!shouldShowNotifModal) {
      const noSoldOutSelection = currentSelections.map((el) => {
        if (el.isSoldOut) {
          el.size = null;
          el.sizeIndex = null;
        }
        return el;
      });
      setCurrentSelections(noSoldOutSelection);
    }

    if (shouldShowNotifModal) {
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      document.body.style.position = "relative";
    }
  }, [shouldShowNotifModal]);

  useEffect(() => {
    let isSoldOut = true;
    for (let option of options) {
      for (let color of option.colors) {
        color.available_sizes.forEach((size, index) => {
          if (isSoldOut && !size.sold_out) {
            isSoldOut = false;
            // updateSelection(0, "SIZE", index);
          }
        });
      }
    }
    setIsFullySoldOut(isSoldOut);
  }, [options]);

  useEffect(() => {
    setValidationError("");
  }, [itemsSelected().count]);

  const updateSelection = (index, type, valueIndex) => {
    const selection = { ...currentSelections[index] };
    if (type == "SIZE") {
      if (selection.sizeIndex === valueIndex) {
        selection.sizeIndex = null;
        selection.size = null;
      } else {
        selection.sizeIndex = valueIndex;
        selection.size =
          options[index].colors[selection.colorIndex]?.available_sizes[
            valueIndex
          ].name;
        selection.isSoldOut =
          options[index].colors[selection.colorIndex]?.available_sizes[
            valueIndex
          ].sold_out;
      }
    }
    if (type == "COLOR") {
      selection.colorIndex = valueIndex;
      selection.color = options[index]?.colors[valueIndex]?.color_name;
    }
    const newSelections = [...currentSelections];
    newSelections.splice(index, 1, selection);
    setCurrentSelections(newSelections);
  };

  const handleAddToCart = async () => {
    if (itemsSelected().count) {
      setIsLoading(true);
      await addToCart(currentSelections[0]);
      setIsLoading(false);
    } else {
      setValidationError("Please fill out the size");
    }
  };

  const enableModalWithData = (data) => {
    setShouldShowNotifModal(true);
  };

  return (
    <div style={{ width: "100%" }}>
      <GetNotifiedModal
        itemsSelected={itemsSelected}
        currentSelections={currentSelections}
        updateSelection={updateSelection}
        options={options}
        pictures={pictures}
        isActive={shouldShowNotifModal}
        setModalVisible={setShouldShowNotifModal}
      />

      {options.map((product, index) => (
        <div className="purchasing-options-container" key={product.type}>
          <p className="price">
            ${product.price}{" "}
            {is_final_sale && (
              <span
                style={{
                  color: "rgba(185, 104, 104, 0.6509803922)",
                  fontSize: "0.75rem",
                  textTransform: "none",
                  fontWeight: 100,
                }}
              >
                Final sale
              </span>
            )}
          </p>

          <div className="container-sizing">
            <a onClick={() => setViewSizingModal(true)}>Sizing Guide</a>
          </div>
          {/* <ColorSelect
                        price={product.price}
                        type={product.type}
                        name={product.name}
                        colors={product.colors}
                        selectedIndex={currentSelections[index].colorIndex}
                        setSelectedIndex={(e) =>
                            updateSelection(index, "COLOR", e)
                        }
                    /> */}
          <SizeSelector
            type={product.type}
            name={product.name}
            enableModalWithData={enableModalWithData}
            sizes={
              product.colors[currentSelections[index].colorIndex]
                ?.available_sizes
            }
            selectedIndex={currentSelections[index].sizeIndex}
            setSelectedIndex={(e) => updateSelection(index, "SIZE", e)}
          />
        </div>
      ))}

      {validationError && <p className="has-text-danger">{validationError}</p>}
      {/* {itemsSelected().count ? (
                <div className='info-selection'>
                    <p className='has-text-info'>
                        Items Selected ({itemsSelected().count})
                    </p>
                    <p>${itemsSelected().price}</p>
                </div>
            ) : null} */}
      <div className="purchasing-options-container">
        <button
          className={`button is-black is-medium add-to-cart ${
            isLoading ? "loading" : ""
          } `}
          onClick={() => {
            isFullySoldOut ? setShouldShowNotifModal(true) : handleAddToCart();
          }}
        >
          {isFullySoldOut ? "Get notified" : "ADD TO BAG"}
        </button>
      </div>
    </div>
  );
};
