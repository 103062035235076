import React, { memo, useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

export default memo(function ({ title, text }) {
  const isDescription = title.toLowerCase() == "description";
  const [isSelected, setIsSelected] = useState(false);
  return (
    <li
      onClick={() => !isDescription && setIsSelected(!isSelected)}
      className={`desc-block-container ${
        isDescription || isSelected ? "is-active" : ""
      }`}
    >
      {!isDescription && (
        <h2
          className={`subtitle description-title ${
            isDescription || isSelected ? "is-active" : ""
          }`}
        >
          {title.split(" ").map(_.capitalize).join(" ")}
          <FontAwesomeIcon
            style={{ paddingTop: "0.5rem" }}
            icon={isSelected ? faAngleLeft : faAngleDown}
          />
        </h2>
      )}
      <ReactMarkdown
        className={`subtitle description ${
          isDescription || isSelected ? "is-active" : ""
        }`}
        style={{ paddingBottom: isDescription || isSelected ? "1rem" : 0 }}
      >
        {text}
      </ReactMarkdown>
    </li>
  );
});
