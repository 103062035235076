import React, { useEffect, useState } from "react";
import MultiEditableValue from "./MultiEditableValue";
import ImageUpload from "../ImageUpload";
import axios from "axios";
import PreviewImages from "./PreviewImages";
import AvailabilityModal from "./AvailabilityModal";
import RelatedItemsModal from "./RelatedItemsModal";

export default (props) => {
  const {
    title,
    meta_title,
    meta_description,
    pictures,
    description,
    url,
    available_products,
    published,
    position,
    promotion_position,
    _id,
    category,
    related_items,
    is_final_sale,
  } = props.data;

  const [item, setItem] = useState({
    title,
    meta_title,
    meta_description,
    pictures,
    description,
    url,
    available_products,
    published,
    position,
    promotion_position,
    _id,
    category,
    related_items,
    is_final_sale,
  });
  const [categories, setCategories] = useState([]);
  const [message, setMessage] = useState({});
  const [availabilityModalVisible, setAvailabilityModalVisible] =
    useState(false);
  const [relatedItemsModalVisible, setRelatedItemsModalVisible] =
    useState(false);
  const [isDeletionModalVisible, setDeletionModalVisible] = useState(false);

  useEffect(() => {
    const getCategories = async () => {
      let result = await axios.get("/getCategories").then((res) => res.data);
      setCategories(result);
    };

    getCategories();
  }, []);

  const updateItem = async () => {
    let result = await axios
      .post("/admin/api/updateItem", {
        _id,
        item,
      })
      .then((res) => res.data);
    if (result.ok) {
      setMessage({ text: "Successfully Updated Item", show: true });
    }
  };

  const setValue = (key, value) => {
    setItem({ ...item, [key]: value });
    setMessage({ show: false });
  };

  const deleteItem = async () => {
    let result = await axios
      .post(" /admin/api/deleteItem", {
        _id,
        item,
      })
      .then((res) => res.data);
    if (result.ok) {
      props.updateItems();
      setDeletionModalVisible(false);
    }
  };

  const DeletionModal = ({ item }) => {
    return (
      <div className="modal is-active">
        <div
          className="modal-background"
          onClick={() => setDeletionModalVisible(false)}
        ></div>
        <div style={{ zIndex: 3 }}>
          <section class="hero">
            <div class="hero-body">
              <div className="box">
                <p class="title">Delete item</p>
                <p class="subtitle">
                  Are you sure you want to completely remove the item?
                </p>
                <div className="buttons is-centered">
                  <button
                    className="button is-primary"
                    onClick={() => setDeletionModalVisible(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="button is-danger"
                    onClick={() => deleteItem()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  };

  return (
    <div className="item-data-container">
      <div className="published-checkbox">
        <label className="checkbox">
          <input
            type="checkbox"
            checked={item.published}
            onChange={(e) => setValue("published", e.target.checked)}
          />
          Published
        </label>
      </div>
      Title:{" "}
      <input
        className="input"
        type="text"
        value={item.title}
        onChange={(e) => setValue("title", e.target.value)}
      />
      <div className="is-final-checkbox">
        <label className="checkbox">
          <input
            type="checkbox"
            checked={item.is_final_sale}
            onChange={(e) => setValue("is_final_sale", e.target.checked)}
          />{" "}
          Is final sale
        </label>
      </div>
      Pictures:{" "}
      <ImageUpload
        field={"pictures"}
        updateValue={(e) =>
          setItem({ ...item, pictures: [...item.pictures, e] })
        }
      />
      {"Image paths"}
      <input
        className="input"
        type="text"
        value={JSON.stringify(item.pictures)}
        onChange={(e) => setValue("pictures", JSON.parse(e.target.value))}
      />
      {item.pictures.length && (
        <PreviewImages images={item.pictures} callback={setValue} />
      )}
      Description:{" "}
      <MultiEditableValue
        fieldKey={"description"}
        values={item.description}
        updateDetails={(details) => setValue("description", details)}
      />
      URL:{" "}
      <input
        className="input"
        type="text"
        value={item.url}
        onChange={(e) => setValue("url", e.target.value)}
      />
      Category:{" "}
      <select
        className="input"
        type="text"
        value={item.category}
        onChange={(e) => setValue("category", e.target.value)}
      >
        <option
          value=""
          disabled
          selected={!item.category || item.category === ""}
        >
          Select your option
        </option>
        {categories.map(({ title, key }) => (
          <option selected={item.category === key} value={key}>
            {title}
          </option>
        ))}
      </select>
      List Position:{" "}
      <input
        className="input"
        type="number"
        value={item.position}
        onChange={(e) => setValue("position", e.target.value)}
      />
      Promotion Position:{" "}
      <input
        className="input"
        type="number"
        value={item.promotion_position}
        onChange={(e) => setValue("promotion_position", e.target.value)}
      />
      {availabilityModalVisible && (
        <AvailabilityModal
          item={item}
          setAvailabilityModalVisible={setAvailabilityModalVisible}
          setValue={setValue}
        />
      )}
      {isDeletionModalVisible && <DeletionModal item={item} />}
      {relatedItemsModalVisible && (
        <RelatedItemsModal
          item={item}
          setRelatedItemsModalVisible={setRelatedItemsModalVisible}
          setValue={setValue}
        />
      )}
      Meta:
      <input
        placeholder="Meta Title"
        className="input"
        type="text"
        value={item.meta_title}
        onChange={(e) => setValue("meta_title", e.target.value)}
      />
      <input
        placeholder="Meta Description (160 chars)"
        className="input"
        type="text"
        value={item.meta_description}
        onChange={(e) => setValue("meta_description", e.target.value)}
      />
      <div className="buttons is-centered">
        <button
          className="button is-danger"
          onClick={() => setDeletionModalVisible(true)}
        >
          Delete
        </button>
        <button
          onClick={() => setRelatedItemsModalVisible(true)}
          className="button is-dark"
        >
          Related info
        </button>
        <button
          className="button is-primary"
          onClick={() => setAvailabilityModalVisible(true)}
        >
          Update Availability
        </button>
        <button onClick={() => updateItem()} className="button is-success">
          Save Item
        </button>
      </div>
      {message.text && message.show ? (
        <h1 className="title has-text-success">{message.text}</h1>
      ) : null}
    </div>
  );
};
