import React, { useEffect, useState } from "react";
import axios from "axios";
import CollectionItem from "../components/CollectionItem/CollectionItem";
import Collection from "../components/Collection";

export default (props) => {
  const [data, setData] = useState([]);

  const getCollection = async () => {
    let data = await axios.get(`/getAllItems`).then((res) => res.data);
    setData(data);
  };

  useEffect(() => {
    getCollection();
  }, []);

  return (
    <div id="collections">
      <div className="hero is-medium">
        <div className="hero-inner" style={{ marginTop: "0.25rem" }}>
          <Collection
            addToCart={props.addToCart}
            className={"collection-container"}
            data={data}
          />
        </div>
      </div>
    </div>
  );
};
