import React, { useState, useEffect } from 'react'
import components from '../ProductLandingContent/components'
import ImageUpload from '../ImageUpload'

export default (props) => {
    const [data, setData] = useState({})
    const fields = components[props.formType].fields

    useEffect(() => {
       if (props.data) {
           setData(props.data)
       }
    }, [])

    const updateValue = (key, value) => {
        let updated = {...data}
        updated[key] = value
        setData(updated)
    }

    const eachKeyFilled = () => {
        for (let key of fields) {
            if (!data[key]) return false
        }
        return true
    }

    return (
        <div>
            {
               fields.map(field => 
                    <div className="field">
                        <label className="label">{field}</label>
                        <div className="control">
                        { field.includes('img')
                            ? <ImageUpload field={field} updateValue={updateValue} />
                            : null
                        }
                        <input className="input" type="text" value={data[field]} onChange={e => updateValue(field, e.target.value)}/>
                        </div>
                    </div>
                )
            }
            <button className='button is-success' disabled={!eachKeyFilled()} onClick={()=>{ props.submit({layout: props.formType, data})}}>Save</button>
        </div>
    )
}
