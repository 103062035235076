import React, { useState } from 'react';
import ColorSelect from './ColorSelect';
import SizeSelector from './SizeSelector'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default ({ isActive, setModalVisible, updateSelection, currentSelections, options, itemsSelected, pictures }) => {
  const [email, setEmail] = useState('')
  const [isSubscribing, setIsSubscribing] = useState(false)
  const [buttonText, setButtonText] = useState('Get Notified')

  const emailValid = () => {
      var mailformat = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      if(!email.match(mailformat)) {
          return false;
      }
      return true
  }

  const submitEmail = async () => {
    setIsSubscribing(true)
    await axios.post('/newsletterSub', 
      { 
        email, 
        itemData: currentSelections.map(
          (selection) => ({
            ...selection, 
            picture: pictures[0]
          })
        )
      }
    )
    setIsSubscribing(false)
    subscribeSuccess()
  }

  const subscribeSuccess = () => {
    setButtonText('Subscribed!')
    setTimeout(() => {
      setModalVisible(false)
    }, 1500)
  }

  const sufficientSelection = () => {
    return itemsSelected().count > 0
  }

  return (
    <div className={`modal ${isActive ?'is-active' : ''}`}>
      <div className="modal-background" onClick={()=> { setModalVisible(false) }}></div>
      <div className="modal-content" style={{ maxWidth: '718px', maxHeight: 'calc(100vh - 50px)'}}>
        <div style={{ position: 'absolute', top: 15, right: 15, zIndex: 100, height: 30, width: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => setModalVisible(false)}><FontAwesomeIcon icon={faTimes}/></div>
        <div className='box get-notified-modal'>
              <h2 className='subtitle has-text-centered is-uppercase'>Be the first one to know when it's available</h2>
              <p style={{ textAlign: 'center' }}>Select preferred size</p>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
              {
                options.map((product, index) => <div className='purchasing-options-container' style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}} key={product.type}>
                  {/* <ColorSelect 
                    price={product.price} 
                    type={product.type} 
                    name={product.name} 
                    colors={product.colors} 
                    selectedIndex={currentSelections[index].colorIndex} 
                    setSelectedIndex={e => updateSelection(index, 'COLOR', e)} 
                  /> */}
                  <SizeSelector 
                    type={product.type} 
                    name={product.name} 
                    soldOutMode={true}
                    sizes={product.colors[currentSelections[index].colorIndex]?.available_sizes} 
                    selectedIndex={currentSelections[index].sizeIndex} 
                    setSelectedIndex={e => updateSelection(index, 'SIZE', e)} 
                  />
                </div>)
              }
              </div>
              {sufficientSelection() && 
                <div>
                  <p style={{ textAlign: 'center' }}>Subscribe to get notifications about this item.</p>
                  <div style={{ maxWidth: '23rem', margin: '0 auto'}}>
                    <input style={{ marginTop: '1rem' }} className='input is-small is-light' placeholder='e-mail' type='text' value={email} onChange={e => setEmail(e.target.value)} />
                    <button disabled={!emailValid() || !sufficientSelection()} style={{width: '100%', height: '3rem', marginTop: '1rem', marginBottom: '1rem', fontSize: '1rem' }} className={`button is-primary ${isSubscribing && 'is-loading'}`} onClick={()=> submitEmail()}>{buttonText}</button>
                  </div>
                </div>
              }
          </div>
        </div>
    </div>
  )
}