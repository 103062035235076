import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import DetailedCartItem from "./Navigation/DetailedCartItem";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

const CartDialogModal = ({
  cart,
  updateCart,
  setModalVisible,
  modalVisible,
  addToCart,
}) => {
  const checkout = () => {
    window.location = "/cart/checkout";
  };

  const reducedCart = useMemo(() => {
    const reducedItems = {};
    const itemKey = (item) =>
      item.title + "/" + item.product_type + "/" + item.color + "/" + item.size;

    cart.forEach((item, originalIndex) => {
      if (reducedItems[itemKey(item)]) {
        reducedItems[itemKey(item)].quantity += 1;
        reducedItems[itemKey(item)].index = originalIndex;
      } else {
        reducedItems[itemKey(item)] = {
          ...item,
          quantity: 1,
          index: originalIndex,
          key: itemKey(item),
        };
      }
    });

    return _.values(reducedItems);
  }, [cart]);

  const subtotal = cart.reduce(
    (total, current) =>
      total +
      Number(
        current.available_products.find((el) => el.type == current.product_type)
          .price
      ),
    0
  );

  return (
    <div className={`modal cart-modal ${modalVisible ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onClick={() => {
          setModalVisible(false);
        }}
      ></div>
      <div className="modal-content cart-modal-content fullheight-overlay slide-right">
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 15,
            zIndex: 100,
            height: 30,
            width: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => setModalVisible(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="cart-modal-box" id="detailedCart">
          <h2
            className="subtitle is-uppercase"
            style={{ marginBottom: "1.5rem" }}
          >
            Shopping Bag
          </h2>

          <div className="cart-items-wrapper">
            {reducedCart.map((cartItem) => (
              <DetailedCartItem
                key={cartItem.key}
                data={cartItem}
                updateCart={updateCart}
                addToCart={addToCart}
              />
            ))}
          </div>
          {!reducedCart.length && (
            <h2 className="subtitle has-text-centered">
              Your shopping bag is empty
            </h2>
          )}
          <div>
            {!!reducedCart.length && (
              <div className="subtotal-container">
                <p>Subtotal</p>
                <p>${subtotal}</p>
              </div>
            )}
            {!!reducedCart.length && (
              <div
                className="buttons is-centered"
                style={{ marginTop: "1rem" }}
              >
                <button className="button is-black" onClick={() => checkout()}>
                  Proceed to Checkout
                </button>
              </div>
            )}
            {!reducedCart.length && (
              <div
                className="buttons is-centered"
                style={{ marginTop: "1rem" }}
              >
                <button
                  className="button is-black"
                  onClick={() => setModalVisible(false)}
                >
                  Continue shopping
                </button>
              </div>
            )}
          </div>

          {/* {data.completeSet?.length > 0 && 
                    <div style={{ width: '80%', margin: '0 auto' }}>
                        <p className="title has-text-centered">Complete the set</p>
                        <div style={{ marginTop: "0.5rem", width: '100%' }}>
                            <Collection className='collection-container-complete-set' data={[...data.completeSet, ...data.similarItems].slice(0,4)} />
                        </div>
                    </div>
                } */}
        </div>
      </div>
    </div>
  );
};

export default CartDialogModal;
