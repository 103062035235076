import React, { useEffect, useState } from "react";
import axios from "axios";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default (props) => {
  const { productId } = props;
  const [reviews, setReviews] = useState([]);

  const addNewReview = () => {
    setReviews([
      ...reviews,
      {
        productId,
        authorName: "",
        title: "",
        description: "",
        rating: 5,
      },
    ]);
  };

  const updateReviewState = (index, field, value) => {
    const currentReview = reviews[index];
    currentReview[field] = value;
    currentReview.isBeingEdited = true;
    setReviews([
      ...reviews.slice(0, index),
      currentReview,
      ...reviews.slice(index + 1),
    ]);
  };

  const listProductReviews = async () => {
    const existingReviews = await axios
      .post("/listReviewsByProductId", { productId })
      .then((res) => res.data.reviews);
    setReviews(existingReviews);
  };

  const createOrUpdateReview = async (index) => {
    const { _id, title, description, rating, authorName } = reviews[index];
    await axios.post("/admin/review", {
      id: _id,
      productId,
      title,
      description,
      rating,
      authorName,
    });

    await listProductReviews();
  };

  const deleteReview = async (index) => {
    const { _id } = reviews[index];
    await axios.post("/admin/deleteReview", {
      id: _id,
    });

    await listProductReviews();
  };

  useEffect(() => {
    listProductReviews();
  }, []);

  return (
    <>
      <p className="title" style={{ marginTop: "2rem" }}>
        Reviews
      </p>
      {reviews?.map((el, index) => {
        const { authorName, title, description, rating, isBeingEdited } = el;
        return (
          <div style={{ display: "flex", margin: "1rem", gap: "0.5rem" }}>
            <input
              placeholder="rating"
              className="input"
              type="number"
              value={rating}
              onChange={(e) =>
                updateReviewState(index, "rating", e.target.value)
              }
            />
            <input
              placeholder="Author Name"
              className="input"
              type="text"
              value={authorName}
              onChange={(e) =>
                updateReviewState(index, "authorName", e.target.value)
              }
            />
            <input
              placeholder="Title"
              className="input"
              type="text"
              value={title}
              onChange={(e) =>
                updateReviewState(index, "title", e.target.value)
              }
            />
            <input
              placeholder="Description"
              className="input"
              type="text"
              value={description}
              onChange={(e) =>
                updateReviewState(index, "description", e.target.value)
              }
            />
            {isBeingEdited && (
              <button
                className="button is-success"
                onClick={() => createOrUpdateReview(index)}
              >
                Save
              </button>
            )}
            <button
              className="button is-danger"
              onClick={() => deleteReview(index)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        );
      })}
      <button className="button is-info" onClick={() => addNewReview()}>
        New Review
      </button>
    </>
  );
};
