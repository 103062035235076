import React from "react";

export default (props) => {
    return (
        <div
            style={{
                padding: "1.5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
            <h1 className='title'>Thank you for your order!</h1>
            <h2 className='subtitle'>
                {`Order has been processed. Your order number is #`}
                {props.orderId}
            </h2>
            <h2 className='subtitle'>
                You will receive the confirmation email shortly.
            </h2>
            <button
                style={{
                    marginTop: "2rem",
                }}
                className='button is-primary is-large'
                onClick={() => {
                    window.location = "/collections";
                }}>
                Continue Shopping
            </button>
        </div>
    );
};
