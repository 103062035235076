import React from "react";
import ProgressiveImage from "react-progressive-image";

const ProgImg = (props) => (
  <ProgressiveImage
    src={props.src}
    placeholder={props.src.slice(0, props.src.length - 4) + "-small.jpg"}
  >
    {(src) => (
      <img style={{ width: "100%", height: "auto" }} src={src} alt="an image" />
    )}
  </ProgressiveImage>
);

export default (props) => {
  return (
    <div id="story" className="hero is-small">
      <div className="hero-body">
        <div className="container">
          <div className="columns" style={{ marginBottom: "4rem" }}>
            <div className="column">
              <div style={{ width: "86%", height: "auto" }}>
                <ProgImg
                  src={
                    "https://murashkilingerie.com/public/uploads/file-1697399712816.jpg"
                  }
                />
              </div>
            </div>
            <div className="column text-padded">
              <p className="is-size-4" style={{ margin: "2rem" }}>
                Murashki is a designer lingerie and ready-to-wear brand,
                established in 2020 in New York, with a passion for elegant
                minimalism and luxury fabrics.
              </p>
              <p className="is-size-4" style={{ margin: "2rem" }}>
                Murashki woman embodies elegance, femininity, and refinement.
                Murashki Lingerie offers limited collections featuring the
                perfect combination of timeless style, unwavering comfort and
                exceptional quality.
              </p>
              <p
                className="is-size-4"
                style={{ margin: "2rem", marginBottom: "4rem" }}
              >
                Murashki (мура́шки meaning goosebumps) is a feeling of excitement
                and pleasure. We are dedicated to making that the way you will
                feel wearing our garments.
              </p>
              <div className="columns is-desktop">
                <div className="column lower-image">
                  <ProgImg
                    src={
                      "https://murashkilingerie.com/public/uploads/file-1697401042225.jpg"
                    }
                  />
                </div>
                <div className="column higher-image">
                  <ProgImg
                    src={
                      "https://murashkilingerie.com/public/uploads/file-1697401066372.jpg"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
