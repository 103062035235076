import React, { useState, useEffect, useMemo, useCallback } from "react";
import ShippingForm from "./ShippingForm";
import ShippingMinified from "./ShippingMinified";
import BillingForm from "./BillingForm";
import lingerieLogo from "../../lingerie-logo@2x.png";
import ShippingOptions from "./ShippingOptions";
import CartList from "../CartList/CartList";
import axios from "axios";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const isUsShipping = (shippingInfo) =>
  /^\d{5}(-\d{4})?$/.test(shippingInfo.zip) &&
  ["us", "usa", "united states", "united states of america"].includes(
    shippingInfo.country.toLowerCase()
  );

console.log(process.env.REACT_APP_PAYMENT_ENV === "local");

const PAYPAL_OPTIONS = {
  clientId:
    "AULOVcTVhWCwyCIlgDDjDTm45ZIXIzMf-QO4yXxzg3qmwtu_j3ew_kBclnEFDMvql0lsgQ-pGJKo3GIb",
  // process.env.REACT_APP_PAYMENT_ENV === "local"
  //   ? "Af0_9jlEI7FbmP5WMvSbNOkLqUqZfBSDIthaG4AYmTPxa4x9xN480z6W150EIaarOu-zQTN27FlLPGnh" // Test
  //   : "AULOVcTVhWCwyCIlgDDjDTm45ZIXIzMf-QO4yXxzg3qmwtu_j3ew_kBclnEFDMvql0lsgQ-pGJKo3GIb", // Prod
  currency: "USD",
  intent: "capture",
  commit: false,
};

// Custom component to wrap the PayPalButtons and show loading spinner
const ButtonWrapper = ({ showSpinner, cart, promo, onApprove }) => {
  const [{ isPending }] = usePayPalScriptReducer();

  async function createOrder() {
    const { data: orderId } = await axios.post("/paypal/createOrder", {
      cart,
      promo,
    });
    return orderId;
  }

  return (
    <>
      {isPending && (
        <FontAwesomeIcon
          className="fa-spin fa-2xl has-text-centered"
          icon={faSpinner}
        />
      )}
      <PayPalButtons
        style={{ layout: "horizontal", color: "silver", tagline: false }}
        fundingSource={"paypal"}
        createOrder={createOrder}
        onApprove={onApprove}
      />
    </>
  );
};

export default ({ subtotal, cart, updateCart }) => {
  const [paypalOrderId, setPayPalOrderId] = useState();
  const [isPayPalLoading, setIsPayPalLoading] = useState(false);
  const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(true);
  const [shippingInfo, setShippingInfo] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    emailConf: "",
    address: "",
    address2: "",
    country: "US",
    city: "",
    state: "",
    zip: "",
  });
  const [billingInfo, setBillingInfo] = useState({
    cardholderName: "",
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    address2: "",
    country: "",
    city: "",
    state: "",
    zip: "",
  });
  const shippingInfoValid = React.useMemo(() => {
    return (
      shippingInfo.firstName !== "" &&
      shippingInfo.email !== "" &&
      shippingInfo.address !== "" &&
      shippingInfo.city !== "" &&
      shippingInfo.zip !== "" &&
      shippingInfo.state !== "" &&
      ((shippingInfo.country !== "US" && shippingInfo.zip.length >= 4) ||
        (shippingInfo.country === "US" && shippingInfo.zip.length >= 5))
    );
  }, [
    shippingInfo.firstName,
    shippingInfo.email,
    shippingInfo.address,
    shippingInfo.city,
    shippingInfo.zip,
    shippingInfo.country,
  ]);
  const [shippingOptionSelected, setShippingOptionSelected] = useState(0);
  const [taxRate, setTaxRate] = useState(null);
  const [stage, setStage] = useState("checkout");
  const [orderId, setOrderId] = useState(0);
  const [promo, setPromo] = useState(null);

  const getCurrentState = async () => {
    let { validStep, orderId, promo } = await axios
      .post("/stageCheck", { stage })
      .then((res) => res.data);
    setPromo(promo);
    setOrderId(orderId);
    setStage(stage);
  };

  useEffect(() => {
    getCurrentState();
    let lebaiserShippingInfo = localStorage.getItem("murashkiShippingInfo");
    if (lebaiserShippingInfo) {
      setShippingInfo(JSON.parse(lebaiserShippingInfo));
    }
  }, []);

  useEffect(() => {
    if (isUsShipping(shippingInfo)) {
      populateTaxRate(shippingInfo.zip.slice(0, 5));
    } else {
      setTaxRate(null);
    }

    if (shippingInfoValid) {
      populateShippingOptions();

      if (paypalOrderId) {
        updatePaypalOrder();
      }
    }
  }, [
    stage,
    shippingInfo.zip,
    shippingInfo.country,
    shippingInfoValid,
    promo,
    paypalOrderId,
  ]);

  const populateTaxRate = async (zipCode) => {
    const resp = await axios.post("/getTaxRate", { zipCode });
    setTaxRate(resp.data.taxRate);
  };

  const updateShippingValues = async (key, value) => {
    setShippingInfo({ ...shippingInfo, [key]: value });
    if (shippingInfoValid === true) {
      localStorage.setItem(
        "murashkiShippingInfo",
        JSON.stringify(shippingInfo)
      );
    }
  };

  const updateBillingValues = (key, value) => {
    setBillingInfo({ ...billingInfo, [key]: value });
  };

  const didProcessStripePayment = async (shipping, additionalInfo) => {
    let result = await axios
      .post("/placeOrder", {
        shippingInfo: shipping || shippingInfo,
        billingInfo,
        shippingType,
        promo,
        additionalInfo,
      })
      .then((res) => res.data);
    if (result.success) {
      localStorage.removeItem("murashkiShippingInfo");
      window.location = "/cart/success";
    }
  };

  const onApprove = async (data) => {
    const { orderID } = data;
    const { data: authorizationDetails } = await axios.post(
      "/paypal/getAuthorizationDetails",
      { orderID }
    );
    const { purchase_units, payer } = authorizationDetails;

    const address_line_2 =
      purchase_units[0].shipping.address.address_line_2 || "";
    const {
      address_line_1,
      admin_area_1,
      admin_area_2,
      country_code,
      postal_code,
    } = purchase_units[0].shipping.address;

    setPayPalOrderId(orderID);
    setShippingInfo({
      firstName: payer.name.given_name,
      lastName: payer.name.surname,
      phone: "",
      email: payer.email_address,
      emailConf: "",
      address: address_line_1,
      address2: address_line_2,
      country: country_code,
      city: admin_area_2,
      state: admin_area_1,
      zip: postal_code,
    });
    setIsPayPalLoading(true);
    setTimeout(() => {
      setStage("shipping");
      setIsPayPalLoading(false);
    }, 500);
  };

  const updatePaypalOrder = async () => {
    if (!paypalOrderId) return;
    await axios.post("/paypal/updateShippingAndTaxes", {
      shippingInfo,
      shippingOptionSelected,
      orderId: paypalOrderId,
      promo,
    });
  };

  const capturePaypalOrder = async () => {
    setIsPayPalLoading(true);
    const { data: captureDetails } = await axios.post("/paypal/capture", {
      orderId: paypalOrderId,
    });
    didProcessStripePayment(shippingInfo, {
      details: captureDetails,
      paymentMethod: "PayPal",
    });
  };

  const subtotalWithDiscount = useMemo(() => {
    return promo && promo.discount
      ? promo.type && promo.type === "dollarAmount"
        ? subtotal - promo.discount
        : subtotal * promo.discount
      : subtotal;
  }, [promo, promo?.type, promo?.discount, subtotal]);

  const [shippingOptions, setShippingOptions] = React.useState();

  const populateShippingOptions = useCallback(async () => {
    const { data: rates } = await axios.post("/order/getShippingOptions", {
      shippingInfo,
    });

    // Free ground shipping and free international over 300
    if (
      (isUsShipping(shippingInfo) && subtotalWithDiscount >= 100) ||
      subtotalWithDiscount >= 300
    ) {
      rates[0].amt = 0;
    }

    setShippingOptions(rates);
  }, [
    subtotalWithDiscount,
    promo,
    subtotal,
    paypalOrderId,
    shippingInfo,
    shippingInfo.zip,
    shippingInfo.country,
  ]);

  const shippingFee =
    shippingOptions && shippingOptions[shippingOptionSelected]
      ? shippingOptions[shippingOptionSelected].amt
      : 0;
  const shippingType =
    shippingOptions && shippingOptions[shippingOptionSelected]
      ? shippingOptions[shippingOptionSelected].type
      : "";

  const grandTotal =
    subtotalWithDiscount >= 0
      ? subtotalWithDiscount + subtotalWithDiscount * taxRate + shippingFee
      : 0 + shippingFee;

  return (
    <div className="checkout-container">
      <PayPalScriptProvider options={PAYPAL_OPTIONS}>
        <div id="stripeElements" className="stripeElements">
          <div className="main">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                style={{ marginTop: "0.5rem" }}
                className="lingerie-logo"
                src={lingerieLogo}
                width={200}
              />
            </div>
            {grandTotal > 0 && stage === "checkout" && (
              <div className="express-checkout">
                <h2 className="subtitle has-text-centered">Express checkout</h2>
                <div className="paypal-container">
                  <ButtonWrapper
                    showSpinner={false}
                    cart={cart}
                    promo={promo}
                    onApprove={onApprove}
                  />
                </div>
                <div className="payment-separator">
                  <span>or</span>
                </div>
              </div>
            )}
            {stage === "checkout" && (
              <ShippingForm
                shippingInfo={shippingInfo}
                update={updateShippingValues}
              />
            )}
            {(stage === "shipping" || stage === "billing") && (
              <ShippingMinified
                stage={stage}
                shippingType={shippingType}
                shippingFee={shippingFee}
                backToShipping={() => setStage("shipping")}
                back={() => setStage("checkout")}
                shippingInfo={shippingInfo}
              />
            )}
            {stage === "shipping" && (
              <>
                {!shippingOptions && (
                  <FontAwesomeIcon
                    className="fa-spin fa-2xl"
                    icon={faSpinner}
                  />
                )}
                {shippingOptions && (
                  <ShippingOptions
                    setSelectedOption={setShippingOptionSelected}
                    isUsShipping={isUsShipping(shippingInfo)}
                    shippingOptionSelected={shippingOptionSelected}
                    options={shippingOptions}
                  />
                )}
              </>
            )}
            {stage === "billing" &&
              (paypalOrderId ? (
                <button
                  className={`button is-black place-order-button ${
                    isPayPalLoading ? "is-loading" : ""
                  }`}
                  style={{ margin: "0.5rem" }}
                  disabled={!paypalOrderId}
                  onClick={() => capturePaypalOrder()}
                >
                  Complete order with PayPal
                </button>
              ) : (
                <>
                  <BillingForm
                    subtotal={subtotal}
                    grandTotal={grandTotal}
                    shippingType={shippingType}
                    shippingFee={shippingFee}
                    taxRate={taxRate}
                    billingInfo={billingInfo}
                    update={updateBillingValues}
                    isBillingSameAsShipping={isBillingSameAsShipping}
                    setIsBillingSameAsShipping={setIsBillingSameAsShipping}
                    didProcessStripePayment={didProcessStripePayment}
                  />
                </>
              ))}
            {stage === "checkout" && (
              <div className="checkout-button-container">
                <button
                  onClick={() => {
                    setStage("shipping");
                  }}
                  disabled={!shippingInfoValid}
                  className={`button is-black place-order-button ${
                    isPayPalLoading ? "is-loading" : ""
                  }`}
                >
                  Continue to shipping
                </button>
              </div>
            )}
            {stage === "shipping" && (
              <div className="checkout-button-container">
                <button
                  onClick={() => {
                    updatePaypalOrder();
                    setStage("billing");
                  }}
                  disabled={!shippingInfoValid}
                  className={`button is-black place-order-button`}
                >
                  Continue to payment
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="stripeElements sidebar">
          <CartList
            cart={cart}
            updateCart={(options) => {
              updateCart(options);
              getCurrentState();
            }}
            promo={promo}
          />
          <div className="shipping-form FormGroup">
            <div className="FormRowTax" style={{ borderTop: "none" }}>
              <p className="FormRowLabelTaxSmall">Subtotal</p>
              <p className="FormRowLabelTaxSmall">${subtotal}</p>
            </div>
            {promo && (
              <div className="FormRowTax">
                <p className="FormRowLabelTaxSmall">Discount</p>
                <p className="FormRowLabelTaxSmall">
                  -$
                  {promo.type && promo.type === "dollarAmount"
                    ? promo.discount.toFixed(2)
                    : (subtotal * (1 - promo.discount)).toFixed(2)}
                </p>
              </div>
            )}
            {shippingOptions && (
              <div className="FormRowTax">
                <p className="FormRowLabelTaxSmall">Shipping</p>
                <p className="FormRowLabelTaxSmall">
                  {shippingFee ? "$" + shippingFee : "free"}
                </p>
              </div>
            )}
            {taxRate !== null && subtotalWithDiscount > 0 && (
              <div className="FormRowTax">
                <p className="FormRowLabelTaxSmall">Tax</p>
                <p className="FormRowLabelTaxSmall">
                  ${(subtotalWithDiscount * taxRate).toFixed(2)}
                </p>
              </div>
            )}
            <div className="FormRowTax">
              <p className="FormRowLabelTax">Total</p>
              <p className="FormRowLabelTax">${grandTotal.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </PayPalScriptProvider>
    </div>
  );
};
