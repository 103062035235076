import React, { useState, useEffect } from "react";
import MultiEditableValue from "./MultiEditableValue";
import ImageUpload from "../ImageUpload";
import axios from "axios";
import PreviewImages from "./PreviewImages";
import AvailabilityModal from "./AvailabilityModal";

export default (props) => {
  const [item, setItem] = useState({
    title: "",
    pictures: [],
    description: [],
    url: "",
    available_products: [],
    meta_title: "",
    position: 0,
    promotion_position: 0,
    meta_description: "",
    category: "",
  });
  const [message, setMessage] = useState("");
  const [availabilityModalVisible, setAvailabilityModalVisible] =
    useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      let result = await axios.get("/getCategories").then((res) => res.data);
      setCategories(result);
    };

    getCategories();
  }, []);

  const validateAndSend = async () => {
    let result = await axios.post("/admin/api/createItem", item);
    if (result.status === 200) {
      setItem({
        title: "",
        pictures: [],
        description: [],
        url: "",
        available_products: [],
        meta_title: "",
        position: 0,
        promotion_position: 0,
        meta_description: "",
        category: "",
      });
      setMessage("successfully created 1 item");
      props.updateItems();
    }
  };

  const setValue = (key, value) => {
    setItem({ ...item, [key]: value });
    setMessage("");
  };

  return (
    <div className="admin">
      <h1 className="title">Create New Item</h1>
      {message !== "" ? <h2 className="subtitle">{message}</h2> : null}

      <div className="item-data-container">
        Title:{" "}
        <input
          className="input"
          type="text"
          value={item.title}
          onChange={(e) => setValue("title", e.target.value)}
        />
        Pictures:{" "}
        <ImageUpload
          field={"pictures"}
          updateValue={(e) =>
            setItem({ ...item, pictures: [...item.pictures, e] })
          }
        />
        {item.pictures.length && (
          <PreviewImages images={item.pictures} callback={setValue} />
        )}
        Description:{" "}
        <MultiEditableValue
          fieldKey={"description"}
          values={item.description}
          updateDetails={(details) => setValue("description", details)}
        />
        URL:{" "}
        <input
          className="input"
          type="text"
          value={item.url}
          onChange={(e) => setValue("url", e.target.value)}
        />
        List Position:{" "}
        <input
          className="input"
          type="number"
          value={item.position}
          onChange={(e) => setValue("position", e.target.value)}
        />
        Promotion Position:{" "}
        <input
          className="input"
          type="number"
          value={item.promotion_position}
          onChange={(e) => setValue("promotion_position", e.target.value)}
        />
        Category:{" "}
        <select
          className="input"
          type="text"
          placeholder="select category"
          value={item.category}
          onChange={(e) => setValue("category", e.target.value)}
        >
          <option value="" disabled selected={item.category === ""}>
            Select your option
          </option>
          {categories.map(({ title, key }) => (
            <option selected={item.category === key} value={key}>
              {title}
            </option>
          ))}
        </select>
        {availabilityModalVisible ? (
          <AvailabilityModal
            item={item}
            setAvailabilityModalVisible={setAvailabilityModalVisible}
            setValue={setValue}
          />
        ) : null}
        Meta:
        <input
          placeholder="Meta Title"
          className="input"
          type="text"
          value={item.meta_title}
          onChange={(e) => setValue("meta_title", e.target.value)}
        />
        <input
          placeholder="Meta Description (160 chars)"
          className="input"
          type="text"
          value={item.meta_description}
          onChange={(e) => setValue("meta_description", e.target.value)}
        />
        <div className="buttons is-centered">
          <button
            className="button is-primary"
            onClick={() => setAvailabilityModalVisible(true)}
          >
            Set Availability
          </button>
          <button
            onClick={() => {
              validateAndSend();
            }}
            className="button is-success"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
