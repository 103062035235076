import React, { useState, useEffect } from 'react'
import axios from 'axios'

export default (props) => {
    const defaultItem = { title: '', text: '', page_name: '', existing: false}
    const [data, setData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [currentItem, setCurrentItem] = useState(defaultItem)

    const loadData = () => {
        axios.get('/admin/api/getAllInfo').then(res => setData(res.data))
    }

    const submitEdit = async () => {
        if (currentItem.existing) {
            await axios.post('/admin/api/updateInfo', currentItem)
        } else {
            await axios.post('/admin/api/createInfo', currentItem)
        }
        loadData()
        setShowModal(false)
    }

    useEffect(()=>{
        loadData()
    }, [])

    return (
        <div id='info-admin' className='info-editor'>
            <div className={`modal ${showModal ? 'is-active' : ''}`}>
                <div className="modal-background" onClick={() => setShowModal(false)}></div>
                <div className="modal-content">
                    <div className='box'>
                        <input placeholder='Page Browser Name' className='input' value={currentItem.page_name} onChange={e => setCurrentItem({...currentItem, page_name: e.target.value})}/>
                        <input placeholder='Title' className='input' value={currentItem.title} onChange={e => setCurrentItem({...currentItem, title: e.target.value})} />
                        <div className="field">
                            <div className="control">
                                <textarea className="textarea is-large" placeholder="All your amazing text." value={currentItem.text} onChange={e => setCurrentItem({...currentItem, text: e.target.value})}></textarea>
                            </div>
                        </div>
                        <button className='button is-success' onClick={()=>{submitEdit()}}>Submit</button>
                    </div>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={() => setShowModal(false)}></button>
            </div>
            { 
                data.map(el => <h1 className='title editable-info' onClick={()=>{setCurrentItem({...el, existing: true}); setShowModal(true)}}>{el.title}</h1>)
            }
            <button className='button is-success' onClick={()=>{setCurrentItem(defaultItem); setShowModal(true)}}>Create New</button>
        </div>
    )
}