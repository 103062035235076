import React, { useEffect, useState } from "react";
import _ from "lodash";
import axios from "axios";

export default (props) => {
  const {
    title,
    size,
    sizeIndex,
    color,
    available_products,
    product_type,
    quantity,
    url,
    index,
  } = props.data;
  const [mainPicture, setMainPicture] = useState(props.data.pictures[0]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const removeFromCart = async (index) => {
    setIsLoading(true);
    await axios.post("/removeFromCart", { index });
    await props.updateCart();
    setIsLoading(false);
  };

  const product = available_products.find((el) => el.type == product_type);

  useEffect(() => {
    let option = product.colors.find((el) => el.color_name === color);
    setSelectedColor(option);
    if (option.color_picture) {
      setMainPicture(option.color_picture);
    }
  }, []);

  const addToCart = async () => {
    setIsLoading(true);
    await props.addToCart({
      color,
      colorIndex: 0,
      isSoldOut: false,
      product_name: product.name,
      product_type: product.type,
      size,
      sizeIndex,
      url,
    });
    setIsLoading(false);
  };

  return (
    <div
      className={`cart-item-wrapper ${isLoading ? "loading" : ""} `}
      key={props.key}
    >
      <div className="cart-item-data-container">
        <div className="title-picture">
          <img
            src={mainPicture.slice(0, mainPicture.length - 4) + "-preview.jpg"}
            width="80px"
            onClick={() => {
              window.location = "/product/" + url;
            }}
          />
        </div>
        <div className="data-wrapper">
          <div className="data-container">
            <p
              className="cart-title"
              onClick={() => {
                window.location = "/product/" + url;
              }}
            >
              {title.split(" ").map(_.capitalize).join(" ")}
            </p>
            <div className="size-color">
              <p>Size: {size}</p>
            </div>
            <p className="price">${product.price}</p>
            <div className="quantity-controls">
              <div
                onClick={() => removeFromCart(index)}
                className="quantity-indicator"
              >
                -
              </div>
              <div className="quantity-indicator">{quantity}</div>
              <div onClick={addToCart} className="quantity-indicator">
                +
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
