import React, { useState, useEffect } from 'react';
import SizePicker from './SizePicker'
import ImageUpload from '../ImageUpload'
import _ from 'lodash'

export default (props) => {
    const defaultColor = {
        color_name: '', 
        color_code: '',
        alt: '',
        available_sizes: [],
    }

    const [colors, setColors] = useState(props.colors)
    const [currentColor, setCurrentColor] = useState(defaultColor)
    const [showModal, setShowModal] = useState(false)

    const submitEdit = () => {
        let {color_name, color_code, available_sizes, index, color_picture, alt} = currentColor
        let temp = [...colors]
        let link = _.snakeCase(color_name)
        if (typeof index !== 'undefined') {
            temp.splice(index, 1, {color_name, color_code, available_sizes, color_picture, link, alt})
        } else {
            temp.push({color_name, color_code, available_sizes, color_picture, link, alt})
        }
        props.setValue(temp)
        setColors(temp)
        setShowModal(false)
    }

    const submitDelete = () => {
        let {index} = currentColor
        if (typeof index !== 'undefined') {
            let temp = [...colors]
            temp.splice(index, 1)
            props.setValue(temp)
            setColors(temp)
        }
        setShowModal(false)
    }

    const editColor = (color, index) => {
        setCurrentColor({...color, index})
        setShowModal(true)
    }

    return (
        <div>
            <div className={`modal ${showModal ? 'is-active' : ''}`}>
                <div className="modal-background" onClick={() => setShowModal(false)}></div>
                <div className="modal-content">
                    <div className='box'>
                        <input placeholder='Color Name' className='input' value={currentColor.color_name} onChange={e => setCurrentColor({...currentColor, color_name: e.target.value})}/>
                        <input placeholder='Color Code' className='input' value={currentColor.color_code} onChange={e => setCurrentColor({...currentColor, color_code: e.target.value})}/>
                        <ImageUpload field={'main_picture'} updateValue={e => setCurrentColor({...currentColor, color_picture: e})} />
                        <input placeholder='Primary Picture for Color (optional)' className='input' value={currentColor.color_picture} onChange={e => setCurrentColor({...currentColor, color_picture: e.target.value})}/>
                        <input placeholder='Alt Description' className='input' value={currentColor.alt} onChange={e => setCurrentColor({...currentColor, alt: e.target.value})}/>
                        <SizePicker url={props.url} sizes={currentColor.available_sizes} setValue={e => setCurrentColor({...currentColor, available_sizes: e})}/>
                        <div className='buttons'>
                            <button className='button is-light' onClick={()=>{submitDelete()}}>Delete</button>
                            <button className='button is-success' onClick={()=>{submitEdit()}}>Save</button>
                        </div>
                    </div>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={() => setShowModal(false)}></button>
            </div>
            Colors:
            <div className='size-container-wrap'>
            {
                colors.length
                ? colors.map((color, i) => <div style={{border: `solid 5px ${color.color_code}`}} className={`color`} onClick={()=> {editColor(color, i)}}>{color.color_name}</div>)
                : 'No colors'
            }
            </div>
            <button className='button is-success' onClick={()=>{setCurrentColor({...defaultColor}); setShowModal(true)}}>Add Color</button>
        </div>
    )
}