import React from 'react';
import ProgressiveImage from 'react-progressive-image'

export default (props) => {
    const { img_1, subtitle } = props.data
    return (
        <div className='fs-hero'>
            <ProgressiveImage 
                className='main-image' 
                src={img_1} 
                placeholder={img_1.slice(0, img_1.length - 4) + '-small.jpg'}
            > 
                {src => <img className='img-1' src={src} style={{width:'auto', maxHeight: '100%'}} alt="an image" />} 
            </ProgressiveImage> 
            <h2 className='subtitle'>{subtitle}</h2>
        </div>
    )
}
