import React from "react";

const ShippingOption = ({
    optionData,
    isSelected,
    setOption,
}) => {
    const { type, description, amt } = optionData;
    return (
        <div
            className={"shippingOption"}
            onClick={() => setOption()}>
            <div className='indicator-container'>
                <div
                    className={`indicator ${
                        isSelected ? "is-selected" : ""
                    }`}></div>
            </div>
            <div style={{ width: '100%'}}>
                <p className='FormRowShippingOption'>{type}</p>
                <p className='shipping-description'>{description}</p>
            </div>
            <p className='shipping-amt'>{`${
                amt === 0 ? "Free" : "$" + amt
            }`}</p>
        </div>
    );
};

export default ({
    shippingOptionSelected,
    setSelectedOption,
    options,
}) => {
    return (
        <div className='optionsList content-box' style={{
            paddingTop: '0.25rem',
            paddingBottom: '0.25rem'
        }}>
            {options?.map((el, index) => (
                <ShippingOption
                    optionData={el}
                    key={"ship-op" + index}
                    index={index}
                    isSelected={shippingOptionSelected == index}
                    setOption={() => setSelectedOption(index)}
                />
            ))}
        </div>
    );
};
