import React from 'react'
import axios from 'axios'

class AuthPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '', 
            password: '',
            redirect: false,
            messageVisible: false,
            isSuccess: false,
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.submitAuth()
        }
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    showMessage = (message, isSuccess) => {
        this.setState({ messageVisible: true, message, isSuccess })
    }

    submitAuth = async () => {
        axios.post(`/authenticate`, {
            email: this.state.email, 
            password: this.state.password
        }).then(res => {
            if (!res.data.error){
                this.props.update()
            }  else {
                this.showMessage('Incorrect email or password', false)
            }
        })   
    }

    render() {
      return (
        <div>
            <section className='hero is-primary is-bold is-fullheight'>
                <div className="hero-body" style={{justifyContent: 'center'}}>
                    <div className='auth-container'>
                        <div className="field">
                            <div className="control">
                                <input placeholder='email' className="input is-rounded" name="email" type="text" onChange={this.handleChange} onKeyPress={this.handleKeyPress}></input>
                            </div>
                        </div>
                        <div>
                            <div className="field">
                                <div className="control">
                                    <input placeholder='password' className="input is-rounded" name="password" type="password"  onChange={this.handleChange} onKeyPress={this.handleKeyPress}></input>
                                </div>
                            </div>
                        </div>
                        {
                            (this.state.messageVisible) 
                                ? <h2 className='subtitle' style={{color: (this.state.isSuccess) ? 'green': 'red'}}>
                                    {this.state.message}
                                  </h2> 
                                : null
                        }
                        <div className="field is-horizontal">
                            <div className="field-body" style={{ justifyContent: 'center'}}>
                                <div className="field is-vcentered" className='sign-in'>
                                    <a onClick={this.submitAuth} href="#" className='button is-primary is-small'>
                                        Sign In
                                    </a> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
      )
    }
}

export default AuthPage
  