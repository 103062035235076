import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import DataForm from './DataForm'


const LandingControls = (props) => {
    const { moveUp, moveDown, remove, edit } = props.actions
    const [editVisible, setEditVisible] = useState(false)
    const submitEdit = (data) => {
        edit(props.index, data)
        setEditVisible(false)
    }

    return ( 
        <div className='landing-controls-container'>
            {
                editVisible
                ? <div className='edit-container'> <DataForm submit={submitEdit} formType={props.data.layout} data={props.data.data}/></div>
                : null
            }
            <FontAwesomeIcon onClick={()=>{ moveUp(props.index) }} icon={faArrowUp}/>
            <FontAwesomeIcon onClick={()=>{ moveDown(props.index) }} icon={faArrowDown}/>
            <FontAwesomeIcon onClick={()=>{ setEditVisible(!editVisible) }} icon={faEdit}/>
            <FontAwesomeIcon onClick={()=>{ remove(props.index) }} icon={faTimes}/>
        </div>
    );
}
 
export default LandingControls;