import React, { useEffect, useState } from "react";
import axios from "axios";
import CartListCard from "./PreCheckoutCartListCard";

export default (props) => {
    const { cart, promo } = props;

    const subtotal = cart.reduce(
        (total, current) =>
            total +
            Number(
                current.available_products.find(
                    (el) => el.type == current.product_type
                ).price
            ),
        0
    );
    const subTotalWithPromo = promo
        ? promo.type && promo.type == "dollarAmount"
            ? subtotal - promo.discount
            : subtotal * promo.discount
        : subtotal;

    return (
        <div>
            <div className='list-of-cards'>
                {cart.map((el, index) => (
                    <CartListCard
                        data={el}
                        index={index}
                        updateCart={props.updateCart}
                    />
                ))}
            </div>
            <div className='total-container'>
                <div className='columns'>
                    <div className='column'>
                        <div className={promo ? "crossed" : ""}>
                            Subtotal: ${subtotal}
                        </div>
                        {promo ? (
                            <div>
                                {" "}
                                Subtotal: $
                                {subTotalWithPromo >= 0
                                    ? subTotalWithPromo.toFixed(2)
                                    : 0}{" "}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};
