import React, { useEffect, useState } from "react";
import logo from "./logo@2x.png";
import Cart from "./Cart";
import axios from "axios";

export default (props) => {
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div
        className="navbar-content"
        style={{
          justifyContent: props.shouldHideCart ? "center" : "space-between",
        }}
      >
        {/* burger */}
        {!props.shouldHideCart && (
          <a
            role="button"
            onClick={() => {
              props.setIsLeftNavModalVisible(true);
            }}
            className={`burger`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarMurashki"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        )}
        <div className="logo">
          {/* logo */}
          <a className="navbar-itm" href="/">
            <img src={logo} width="120px" />
          </a>
        </div>

        {!props.shouldHideCart && (
          <>
            <div className="navbar-item">
              <Cart
                setExpanded={props.setIsCartDialogModalVisible}
                updateCart={props.updateCart}
              />
              {props.cart.length ? (
                <div className="badge">{props.cart.length}</div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </nav>
  );
};
