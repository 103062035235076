import PicLayout2L from './PicLayout2L'
import TextDataLayout1 from './TextDataLayout1'
import tallWithSmallOverlayText from './tallWithSmallOverlayText'
import wideHorizontalWithText from './wideHorizontalWithText'
import fullscreenHero from './fullscreenHero'
import columnsLargeRight from './columnsLargeRight'

export default {
    'picLayout2L' : {
        'component' : PicLayout2L,
        'fields'    : ['img_1', 'img_2'],
        'name'      : 'Two Large Pictures',
    },
    'textDataLayout1' : {
        'component' : TextDataLayout1,
        'fields'    : ['title', 'subtitle'],
        'name'      : 'Titile And Subtitle',
    },
    'tallWithSmallOverlayText' : {
        'component' : tallWithSmallOverlayText,
        'fields'    : ['img_1', 'img_2', 'subtitle'],
        'name'      : 'Tall Picture w/ Small Overlay & Text',
    },
    'wideHorizontalWithText' : {
        'component' : wideHorizontalWithText,
        'fields'    : ['img_1', 'subtitle'],
        'name'      : 'Wide Picture w/ Text', 
    },
    'fullscreenHero' : {
        'component' : fullscreenHero,
        'fields'    : ['img_1', 'subtitle'],
        'name'      : 'Wide Hero Picture', 
    },
    'columnsLargeRight' : {
        'component' : columnsLargeRight,
        'fields'    : ['img_1', 'img_2', 'subtitle'], 
        'name'      : 'Two Pictures w/ text on right',
    },
}