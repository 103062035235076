import React, { useState, useCallback, useEffect } from "react";
import CollapsableBlock from "./CollapsableBlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

export default (props) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    if (props.data && props.data.length) {
      let firstSelected = props.data.map((el, i) => ({
        ...el,
        selected: i === 0 ? true : false,
      }));
      setList(firstSelected);
    }
  }, [props.data]);

  const handleClick = useCallback((key) => {
    setList((list) =>
      list.map((el) => ({ ...el, selected: el.key === key ? true : false }))
    );
  }, []);

  return (
    <ul>
      {list.map((item) => (
        <CollapsableBlock
          {...item}
          itemKey={item.key}
          key={item.key}
          handleClick={handleClick}
        />
      ))}
      <li
        onClick={() => props.showReviews()}
        className={`desc-block-container`}
      >
        <h2 className={`subtitle description-title`}>
          {"Reviews"}
          <FontAwesomeIcon
            style={{ paddingTop: "0.5rem" }}
            icon={faAngleDown}
          />
        </h2>
      </li>
    </ul>
  );
};
