import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";

export default function NewsletterCreator({ selectedNewsletter, onSave }) {
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [scheduledAt, setScheduledAt] = useState("");
  const [status, setStatus] = useState("Draft");

  const today = new Date().toISOString().slice(0, -8);
  console.log({ today, scheduledAt });

  useEffect(() => {
    if (selectedNewsletter) {
      setSubject(selectedNewsletter.subject);
      setContent(selectedNewsletter.emailContent);
      setStatus(selectedNewsletter.status);
      setScheduledAt(
        new Date(selectedNewsletter.scheduledAt).toISOString().slice(0, -8)
      );
    }
  }, [selectedNewsletter]);

  const handleSave = async () => {
    const payload = { subject, emailContent: content, scheduledAt, status };
    if (!subject || !content || !scheduledAt) {
      alert("All fields are required.");
      return;
    }
    if (selectedNewsletter) {
      await axios.post("/admin/api/editNewsletter", {
        ...payload,
        id: selectedNewsletter._id,
      });
    } else {
      await axios.post("/admin/api/createNewsletter", payload);
    }
    onSave();
  };

  return (
    <div id="newsletter-editor">
      Subject:
      <input
        type="text"
        placeholder="Subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      Body:
      <div className="editor-container">
        <textarea
          placeholder="Content (Markdown supported)"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <div className="markdown-preview">
          <ReactMarkdown className="info-text">{content}</ReactMarkdown>
        </div>
      </div>
      Status:
      <select value={status} onChange={(e) => setStatus(e.target.value)}>
        <option value="Draft">Draft</option>
        <option value="Approved">Approved</option>
        <option value="Needs review">Needs review</option>
      </select>
      Date:
      <input
        type="datetime-local"
        value={scheduledAt}
        min={today}
        onChange={(e) => setScheduledAt(e.target.value)}
      />
      <button onClick={handleSave} className="button is-success is-rounded">
        Save
      </button>
    </div>
  );
}
