import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import CardSection from "./CardSection";

export default (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckoutReady, setIsCheckoutReady] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const checkoutReady = () => {
    if (props.grandTotal <= 0) return true;
    const cardElementContainer = document.querySelector(".StripeElement");

    const containsComplete = cardElementContainer?.classList.contains(
      "StripeElement--complete"
    );

    setIsCheckoutReady(containsComplete);
  };

  useEffect(() => {
    let shouldCheck = true;

    setInterval(() => {
      shouldCheck && checkoutReady();
    }, 200);

    return () => {
      shouldCheck = false;
    };
  }, []);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setErrorMessage(null);
    const cardElementContainer = document.querySelector(".StripeElement");

    if (props.grandTotal > 0) {
      let cardElementComplete = cardElementContainer.classList.contains(
        "StripeElement--complete"
      );
      if (!cardElementComplete) return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const CLIENT_SECRET = await axios
      .post("/api/getClientSecret", {
        taxRate: props.taxRate,
        shippingFee: props.shippingFee,
      })
      .then((res) => res.data);
    if (CLIENT_SECRET == "SKIP_PAYMENT") {
      props.didProcessStripePayment();
      return;
    }

    const result = await stripe.confirmCardPayment(`${CLIENT_SECRET}`, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name,
        },
      },
    });

    if (result.error) {
      setErrorMessage(result.error.message);
      // Show error to your customer (e.g., insufficient funds)
      setIsLoading(false);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        props.didProcessStripePayment(undefined, {
          paymentMethodId: result.paymentIntent.payment_method,
        });
      }
    }
  };

  return (
    <>
      <div className="content-box">
        {props.grandTotal > 0 && (
          <form className="FormGroup">
            <div className="FormRow">
              <input
                className="FormRowInput"
                type="text"
                placeholder="Cardholder Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <CardSection />
          </form>
        )}
        <div className="checkout-button-container">
          <button
            className={`button is-black place-order-button ${
              isLoading ? "is-loading" : ""
            }`}
            style={{ margin: "0.5rem" }}
            disabled={!isCheckoutReady}
            onClick={(e) => handleSubmit(e)}
          >
            PLACE ORDER
          </button>
        </div>
      </div>
      {!!errorMessage && (
        <article class="message is-danger">
          <div class="message-header">
            <p>Payment Error</p>
            <button
              onClick={() => setErrorMessage(null)}
              class="delete"
              aria-label="delete"
            ></button>
          </div>
          <div class="message-body">{errorMessage}</div>
        </article>
      )}
    </>
  );
};
