import React from "react";

export default ({ isActive, setModalVisible }) => {
    return (
        <div className={`modal ${isActive ? "is-active" : ""}`}>
            <div
                className='modal-background'
                onClick={() => {
                    setModalVisible(false);
                }}></div>
            <div
                className='modal-content'
                style={{ maxHeight: "calc(100vh - 50px)" }}>
                <div className='box' style={{ padding: "3rem" }}>
                    <h2 className='subtitle has-text-centered is-uppercase'>
                        We received your return request
                    </h2>
                    <p style={{ textAlign: "center" }}>
                        The confirmation email with shipping instructions has been sent
                        to the email assosiated with the order.{" "}
                    </p>

                    <div className='buttons is-centered' style={{ marginTop: '2rem' }}>
                        <button
                            className='button is-primary'
                            onClick={() => setModalVisible(false)}>
                            Continue Shopping
                        </button>
                    </div>
                </div>
            </div>
            <button
                className='modal-close is-large'
                aria-label='close'
                onClick={() => setModalVisible(false)}></button>
        </div>
    );
};
