import React, { useEffect, useState } from "react";
import axios from "axios";
import CartWithTotal from "./PreCheckoutCartWithTotal";
import StepIndicator from "../StepIndicator/StepIndicator";

export default (props) => {
    const { cart, promo } = props;

    const proceedToCheckout = async () => {
        await axios.post("/requestCheckout");
        window.location = "/cart/checkout";
    };

    return (
        <div id='cart-list'>
            {cart.length ? (
                <div>
                    <StepIndicator step='cart' />
                    <CartWithTotal
                        updateCart={props.updateCart}
                        cart={cart}
                        promo={promo}
                    />
                    <div className='proceed-button-container'>
                        <button
                            onClick={() => {
                                proceedToCheckout();
                            }}
                            className='button is-black'>
                            CHECKOUT
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <p style={{ margin: "5rem", textAlign: "center" }}>
                        Your Shopping Cart Is Empty
                    </p>
                </div>
            )}
        </div>
    );
};
