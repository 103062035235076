import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import CartListCard from "./CartListCard";
import _ from "lodash";

export default (props) => {
  const { cart, promo } = props;
  const [promoCode, setPromoCode] = useState(promo ? promo.code : "");
  const [invalidPromo, setInvalidPromo] = useState(false);

  useEffect(() => {
    promo?.code && setPromoCode(promo?.code);
  }, [promo]);

  const applyPromo = async () => {
    let result = await axios
      .post("/applyPromo", { promo: promoCode })
      .then((res) => res.data);
    props.updateCart({ shouldHideCartModal: true });
    if (!result.success) {
      setInvalidPromo(true);
    } else {
      setInvalidPromo(false);
    }
  };

  const reducedCart = useMemo(() => {
    const reducedItems = {};
    const itemKey = (item) =>
      item.title + "/" + item.product_type + "/" + item.color + "/" + item.size;

    cart.forEach((item, originalIndex) => {
      if (reducedItems[itemKey(item)]) {
        reducedItems[itemKey(item)].quantity += 1;
        reducedItems[itemKey(item)].index = originalIndex;
      } else {
        reducedItems[itemKey(item)] = {
          ...item,
          quantity: 1,
          index: originalIndex,
        };
      }
    });

    return _.values(reducedItems);
  }, [cart]);

  return (
    <div>
      <div className="list-of-cards">
        {reducedCart.map((el, index) => (
          <CartListCard data={el} index={index} updateCart={props.updateCart} />
        ))}
      </div>
      <div className="total-container">
        <div className="columns">
          <div className="column promo-container buttons">
            <div className="field">
              <input
                placeholder={"Gift Card or Promo"}
                className={`input promo ${invalidPromo ? "is-danger" : ""} ${
                  promo ? "is-success" : ""
                }`}
                value={promoCode}
                onChange={(e) => {
                  setPromoCode(e.target.value);
                }}
              />
              <p
                className={`help is-danger ${invalidPromo ? "" : "is-hidden"}`}
              >
                Invalid Promo Code
              </p>
            </div>
            <button
              className="button is-light"
              style={{ marginLeft: "1rem" }}
              onClick={() => applyPromo()}
            >
              Apply Promo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
