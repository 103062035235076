import React from 'react'

export default ({ step }) => {

    return (
        <div className='step-indicator-container'>
        {
            step == 'cart' && <h1 className='title' style={{ textAlign: 'center', width: '100%' }}>Shopping bag</h1>
        }
        {
            step == 'checkout' && <h1 className='title' style={{ textAlign: 'center', width: '100%' }}>Checkout</h1>
        }
        </div>
    )
}