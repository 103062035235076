import React from 'react';

export default (props) => {
    let { sizes, setSelectedIndex, selectedIndex, type, name, enableModalWithData, soldOutMode } = props

    const SizeOption = function({data, onClick, selectedIndex, index}) {
        if (soldOutMode && !data.sold_out) return null

        const handleSelectSize = (index) => {
            if (selectedIndex !== index || !soldOutMode) onClick(index)
            if (data.sold_out && !soldOutMode) enableModalWithData({ type, name, size: data.name })
        }
        return (
            <div className='size-option-wrapper'>
                <div 
                    className={ `size-option ${selectedIndex === index ? 'selected' : ''} ${data.sold_out && !soldOutMode ? 'sold-out' : ''}` } 
                    onClick={ () => handleSelectSize(index) }
                >
                    { data.name }
                </div>
                {
                    <p className='sold-out-text'>{data.sold_out && !soldOutMode ? 'sold out' : ''}</p>
                }
            </div>
        )
    }

    return <div className='size-selector-container'>
        { sizes && sizes.length 
            ? sizes.map((el, i) => <SizeOption data={el} index={i} onClick={setSelectedIndex} selectedIndex={selectedIndex} />) 
            : null
        }
    </div>
    
}
