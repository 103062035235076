import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import ReturnConfirmationModal from '../components/ReturnConfirmationModal';

export default (props) => {
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('promoCode');

  const [promoCode, setPromoCode] = useState(code || '')
  const [password, setPassword] = useState('')
  const [viewError, setViewError] = useState(false)
  const [performanceData, setPerformanceData] = useState([])
  const [passAccepted, setPassAccepted] = useState(false)
  
  const collectData = async () => {
    try {
      setViewError(false)
      const res = await axios.post('/promoPerformance', { promoCode, password })
      setPerformanceData(res.data)
      setPassAccepted(true)
    } catch {
      setViewError(true)
    }
  }

  return (
    <div id="story" className='hero is-fullheight'>
       <div className='hero-body'>
          <div id='cart-list' className='container'>
            <h1 className='title' style={{ marginBottom: '2.25rem'}}>Campaign Performance</h1>
            <p style={{padding: '1rem', textAlign: 'center'}}>Let's see how well this campaign does</p>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <input style={{ maxWidth: '23rem' }} className="input" value={promoCode} onChange={e => setPromoCode(e.target.value)}/>
              <input style={{ maxWidth: '23rem' }} placeholder='password' type='password' className="input" value={password} onChange={e => setPassword(e.target.value)}/>
              <button className='button is-primary' disabled={password == ''} onClick={()=>{ collectData() }}>Search</button>
            </div>
            {viewError && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <p style={{padding: '1rem', textAlign: 'center' }}>Code or password is wrong...</p>
            </div>}
            {
              passAccepted && performanceData.length === 0 && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <p style={{padding: '1rem', textAlign: 'center' }}>There has been no sales with this promo yet...</p>
              </div>
            }
            {
              performanceData.length !== 0 && 
              <table className='table is-striped is-narrow is-hoverable is-fullwidth is-bordered' style={{minWidth: '375px', maxWidth: '800px', margin: '0.5rem auto'}}>
                <thead>
                  <th>Order ID</th>
                  <th>Total</th>
                  <th>Discount</th>
                  <th>Payout</th>
                </thead>
                <tbody>
                  {
                    performanceData.map(row => (
                      <tr>
                        <td>{row.orderId}</td>
                        <td>${row.total}</td>
                        <td>{Math.round( 100 * (-1 + row.discount))}%</td>
                        <td>${row.payout}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            }
          </div>
        </div>
    </div>
  )
}