import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default (props) => {
    const [data, setData] = useState({
        title: "",
        text: "",
    });

    useEffect(() => {
        let requestedPage = props.match.params[0];
        axios
            .get("/getInfo", { params: { requestedPage } })
            .then((res) => setData(res.data));
    }, []);

    return (
        <div
            id='info'
            className='hero is-large'
            style={{ marginBottom: "2rem", marginTop: "2rem" }}>
            <h1 className='title' style={{ fontSize: "2rem" }}>
                {data.title}
            </h1>
            {data.title == "" && <FontAwesomeIcon icon={faSpinner} />}
            <ReactMarkdown className='info-text'>{data.text}</ReactMarkdown>
            <div className='buttons is-centered'>
                <button
                    className='button is-large is-light primary-button'
                    onClick={() => {
                        window.location = "/collections";
                    }}>
                    Continue Shopping
                </button>
            </div>
        </div>
    );
};
