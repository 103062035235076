import React, { useState, useEffect } from "react";
import OrderForm from "../components/OrderForm/OrderForm";
import PreCheckoutCart from "../components/PreCheckoutCart/PreCheckoutCart";
import axios from "axios";
import OrderFinish from "../components/OrderFinish/OrderFinish";

export default (props) => {
  const [stage, setStage] = useState("cart");
  const [orderId, setOrderId] = useState("");

  const getCurrentState = async () => {
    let requestedStage = props.match.params[0];
    let validStages = ["checkout", "success"];
    if (validStages.indexOf(requestedStage) === -1)
      return (window.location = "/cart/checkout");
    let { validStep, orderId, promo } = await axios
      .post("/stageCheck", { stage: requestedStage })
      .then((res) => res.data);
    if (
      validStages.indexOf(requestedStage) > validStages.indexOf(validStep) &&
      requestedStage !== "/cart/" + validStep
    ) {
      window.location = "/cart/" + validStep;
    }

    setOrderId(orderId);
    setStage(validStep);
  };

  useEffect(() => {
    getCurrentState();
  }, []);

  return (
    <div id="checkout">
      <div className="hero is-fullheight-with-navbar">
        {stage === "shopping-bag" ? (
          <div className="hero-body">
            <div className="container is-narrow">
              <PreCheckoutCart
                cart={props.cart}
                updateCart={() => {
                  props.updateCart();
                  getCurrentState();
                }}
                promo={""}
              />
            </div>
          </div>
        ) : stage === "checkout" ? (
          <OrderForm
            {...props}
            subtotal={props.cart.reduce(
              (total, current) =>
                total +
                Number(
                  current.available_products.find(
                    (el) => el.type == current.product_type
                  ).price
                ),
              0
            )}
          />
        ) : stage === "success" ? (
          <div className="hero-body">
            <div className="container is-narrow">
              <OrderFinish orderId={orderId} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
