import React, { useEffect, useState } from "react";

export default ({ addToCart, data }) => {
  const { available_products, url } = data;
  const [isFlipped, setIsFlipped] = useState(false);
  const [isFullySoldOut, setIsFullySoldOut] = useState(false);

  const flipOut = () => {
    setTimeout(() => setIsFlipped(false), 100);
  };

  const flipIn = () => {
    setTimeout(() => setIsFlipped(true), 100);
  };

  const handleAddToCart = (data) => {
    // color: "Blue"
    // colorIndex: 0
    // isSoldOut: false
    // product_name: "Lorna"
    // product_type: "Bralette"
    // size: "L"
    // sizeIndex: 2
    // url: "lorna"

    addToCart(data);
  };

  useEffect(() => {
    for (let size of available_products[0]?.colors[0]?.available_sizes) {
      if (!size.sold_out) {
        return;
      }
    }
    setIsFullySoldOut(true);
  }, [available_products]);

  return (
    <div className="buttons is-centered">
      {isFlipped ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
          onMouseEnter={() => flipIn()}
          onMouseLeave={() => flipOut()}
        >
          {available_products[0]?.colors[0]?.available_sizes.map(
            (size, index) => {
              return (
                !size.sold_out && (
                  <div style={{ marginRight: "4px", marginBottom: "0.5rem" }}>
                    <div
                      className={`size-option`}
                      onMouseEnter={() => flipIn()}
                      onClick={(e) =>
                        handleAddToCart({
                          color: available_products[0]?.colors[0]?.color_name,
                          colorIndex: 0,
                          isSoldOut: false,
                          product_name: available_products[0]?.name,
                          product_type: available_products[0]?.type,
                          size: size.name,
                          sizeIndex: index,
                          url,
                        })
                      }
                    >
                      {size.name}
                    </div>
                  </div>
                )
              );
            }
          )}
        </div>
      ) : (
        <button
          disabled={isFullySoldOut}
          // style={{ marginBottom: '1rem'}}
          onMouseOut={() => !isFullySoldOut && flipOut()}
          onMouseLeave={() => !isFullySoldOut && flipOut()}
          onMouseEnter={() => !isFullySoldOut && flipIn()}
          onClick={(e) => {
            !isFullySoldOut && flipIn();
          }}
          onTouchStart={() => {
            !isFullySoldOut && flipIn();
          }}
          className="button is-small is-rounded"
        >
          Add to bag
        </button>
      )}
    </div>
  );
};
