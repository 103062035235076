import React, { useEffect, useState } from "react";
import axios from "axios";
import CartListWithTotal from "./CartListWithTotal";
import StepIndicator from "../StepIndicator/StepIndicator";

export default (props) => {
    const { cart, promo } = props;

    return (
        <div id='cart-list'>
            {cart.length ? (
                <div style={{ paddingTop: '2rem' }}>
                    {/* <StepIndicator step='cart' /> */}
                    <CartListWithTotal
                        updateCart={props.updateCart}
                        cart={cart}
                        promo={promo}
                    />
                </div>
            ) : (
                <div>
                    <p style={{ margin: "5rem", textAlign: "center" }}>
                        Your Shopping Cart Is Empty
                    </p>
                </div>
            )}
        </div>
    );
};
