import React, { useEffect, useState } from "react";
import ExistingItem from "./ExistingItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default (props) => {
  const [searchString, setSearchString] = useState("");
  const [toRender, setToRender] = useState([]);

  useEffect(() => {
    if (props.existingItems && props.existingItems.length) {
      if (searchString === "") {
        setToRender(props.existingItems);
      } else {
        let filtered = props.existingItems.filter((e) =>
          e.title.includes(searchString)
        );
        setToRender(filtered);
      }
    }
  }, [props.existingItems]);

  const updateSearch = (value) => {
    setSearchString(value);
    if (value !== "") {
      let filtered = props.existingItems.filter((e) =>
        e.title.toLowerCase().includes(value.toLowerCase())
      );
      setToRender(filtered);
    } else {
      setToRender(props.existingItems);
    }
  };

  return (
    <div className="admin">
      <h1 className="title">Existing Items</h1>
      <div className="field">
        <p className="control has-icons-left">
          <input
            placeholder="search"
            className="input is-rounded"
            value={searchString}
            onChange={(e) => updateSearch(e.target.value)}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </p>
      </div>
      <div className="existing-items-container">
        {toRender.length ? (
          toRender.map((el) => (
            <ExistingItem
              data={el}
              key={el.title}
              updateItems={props.getExistingItems}
            />
          ))
        ) : (
          <h2 className="subtitle">No Search Results</h2>
        )}
      </div>
    </div>
  );
};
