import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Video from "../Video/Video";

export default (props) => {
  const { images, callback } = props;

  const move = (index, moveAmount) => {
    const arr = [...images];
    const element = arr[index];
    arr.splice(index, 1);
    arr.splice(index + moveAmount, 0, element);
    callback("pictures", arr);
  };

  const remove = (index) => {
    const arr = [...images];
    arr.splice(index, 1);
    callback("pictures", arr);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        // height: "15rem",
        margin: "1rem 0",
      }}
    >
      {images.map((el, index) => (
        <div style={{ position: "relative", margin: "0.5rem" }}>
          <FontAwesomeIcon
            style={{
              zIndex: 10,
              position: "absolute",
              top: 5,
              right: 7,
              cursor: "pointer",
            }}
            onClick={() => {
              remove(index);
            }}
            icon={faTimes}
          />
          {index !== 0 && (
            <FontAwesomeIcon
              style={{
                zIndex: 10,
                position: "absolute",
                bottom: 7,
                left: 7,
                cursor: "pointer",
              }}
              onClick={() => {
                move(index, -1);
              }}
              icon={faArrowLeft}
            />
          )}
          {index !== images.length - 1 && (
            <FontAwesomeIcon
              style={{
                zIndex: 10,
                position: "absolute",
                bottom: 7,
                right: 7,
                cursor: "pointer",
              }}
              onClick={() => {
                move(index, 1);
              }}
              icon={faArrowRight}
            />
          )}
          {el.includes(".jpg") ? (
            <img src={el} style={{ width: "10rem" }} />
          ) : (
            <div style={{ width: "12rem" }}>
              <Video aspectRatio={"2:3"} src={el} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
