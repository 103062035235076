import React, { useState, useEffect } from "react";
import ProgressiveImage from "react-progressive-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import lingerieLogo from "../../lingerie-logo@2x.png";
import axios from "axios";
import useScreen from "../../hooks/useScreen";

export default ({ declinePromo }) => {
  const PROMO_PIC_URL =
    "https://murashkilingerie.com/public/uploads/file-1708287231478.jpg";
  const { width } = useScreen();
  const [message, setMessage] = useState({
    type: "",
    value: "",
  });
  const [email, setEmail] = useState("");
  const emailValid = () => {
    const VALID_EMAIL_REGEX =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    if (!email.match(VALID_EMAIL_REGEX)) {
      return false;
    }
    return true;
  };

  const submitEmail = async () => {
    if (!emailValid()) {
      setMessage({ type: "ERROR", value: "Invalid email address." });
      return;
    }
    const res = await axios.post("/newsletterSub", { email });
    if (res.data.success) {
      setMessage({
        type: "SUCCESS",
        value:
          "Successfully subscribed! Check your email for the discount code.",
      });
      setTimeout(() => declinePromo(), 5000);
    } else {
      setMessage({ type: "ERROR", value: "Email already exists" });
    }
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={() => declinePromo()}></div>
      <div
        className="modal-content"
        style={{
          overflow: "hidden",
          borderRadius: "6px",
          maxWidth: "700px",
          maxHeight: "75vh",
          zIndex: 100,
        }}
      >
        <div className="box" style={{ padding: 0 }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 100,
              width: 35,
              height: 35,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => declinePromo()}
          >
            <FontAwesomeIcon
              style={{ color: width <= 450 ? "white" : "#000" }}
              icon={faTimes}
            />
          </div>
          <div className="columns is-gapless is-mobile">
            <div className="column large-screen-only" style={{ padding: 0 }}>
              <ProgressiveImage
                src={PROMO_PIC_URL}
                placeholder={
                  PROMO_PIC_URL.slice(0, PROMO_PIC_URL.length - 4) +
                  "-small.jpg"
                }
              >
                {(src) => (
                  <img
                    className="img-promo-large"
                    src={src}
                    style={{ width: "100%" }}
                    alt="an image"
                  />
                )}
              </ProgressiveImage>
            </div>
            <div
              className="column"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
              }}
            >
              {width >= 450 && (
                <img
                  className="image img-promo-lingerie"
                  style={{ margin: "2rem 0 0 0" }}
                  src={lingerieLogo}
                />
              )}
              {width < 450 && (
                <img
                  className="image img-promo"
                  style={{ margin: 0 }}
                  src={
                    "https://murashkilingerie.com/public/uploads/file-1708287231478.jpg"
                  }
                />
              )}
              <p className="promo-text-large">Enjoy 15% off</p>
              <p className="promo-text-small">your order today</p>
              {message.value !== "" && (
                <p
                  style={{ textTransform: "none" }}
                  className={`is-size-7 ${
                    message.type == "ERROR"
                      ? "has-text-danger"
                      : "has-text-success"
                  }`}
                >
                  {message.value}
                </p>
              )}
              <input
                style={{ marginTop: "1rem", width: "80%", maxWidth: "350px" }}
                className="input is-small is-light"
                placeholder="Subscribe to our newsletter"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                style={{
                  maxWidth: "350px",
                  width: "80%",
                  height: "2rem",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  fontSize: "0.75rem",
                }}
                className="button is-light promo-buttn"
                onClick={() => submitEmail()}
              >
                GET 15% OFF
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
