import React from "react";
import CollectionItem from "./CollectionItem/CollectionItem";

export default ({ data, className, addToCart }) => {
    return (
        <div className={className}>
            {data.map((item, index) => (
                <CollectionItem
                    addToCart={addToCart}
                    key={"colection-item-" + index}
                    data={item}
                />
            ))}
        </div>
    );
};
