import React from "react";
import { CardElement } from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "#505050",
            fontWeight: 400,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: "16px",
            padding: '11px 15px 11px 0',
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#fce883" },
            "::placeholder": {
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: "17px",
                color: "#505050",
                fontWeight: 700,
                fontSize: "16px",
                padding: '11px 15px 11px 0',
                fontSmoothing: "antialiased",
            },
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee",
        },
    },
};

function CardSection({}) {
    return (
        <fieldset className='FormGroup'>
            <div className='FormRow'>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>
        </fieldset>
    );
}

export default CardSection;
