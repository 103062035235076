import React, { useEffect, useState } from "react";
import axios from "axios";

const NonEmptyCarts = () => {
  const [carts, setCarts] = useState([]);

  useEffect(() => {
    const fetchCarts = async () => {
      const response = await axios.get("/admin/api/getNonEmptyCarts");
      setCarts(response.data);
    };
    fetchCarts();
  }, []);

  const calculateSubtotal = (cart) => {
    return cart
      .reduce((total, item) => {
        return total + parseFloat(item.available_products?.[0]?.price ?? 0);
      }, 0)
      .toFixed(2);
  };

  const renderOrderDetails = (order) => {
    return Object.entries(order ?? {}).map(([key, value]) => (
      <p key={key}>
        <strong>{key}:</strong> {JSON.stringify(value, null, 2)}
      </p>
    ));
  };

  return (
    <div>
      <h1 className="title">Non-Empty Carts</h1>
      <table className="table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Cart</th>
            <th>Order</th>
            <th>Items in Cart Count</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {carts.map((cart, index) => (
            <tr key={index}>
              <td>
                {cart.cart.map((item, itemIndex) => (
                  <div key={itemIndex}>
                    <p>
                      {item.product_name} - {item.product_type}, Size:{" "}
                      {item.size} - ${item?.available_products?.[0]?.price}
                    </p>
                    <hr />
                  </div>
                ))}
              </td>

              <td>
                {JSON.stringify(cart.order)}
                {renderOrderDetails(cart.order)}
                <p>
                  <strong>Subtotal:</strong> ${calculateSubtotal(cart.cart)}
                </p>
              </td>
              <td>{cart.cart.length}</td>
              <td>{new Date(cart.createdAt).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NonEmptyCarts;
