import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import Collection from "../components/Collection";

export default (props) => {
  const [data, setData] = useState([]);
  const search = useLocation().search;
  const history = useHistory();

  const category = new URLSearchParams(search).get("category");

  const currentCategory = useMemo(() => {
    return props.categories?.find((el) => el.key == category);
  }, [props.categories, category]);

  const getCollection = async () => {
    await axios.post(`/getCategory`, { category }).then((res) => {
      if (!res.data.length) return history.push("/collections");
      setData(res.data);
    });
  };

  useEffect(() => {
    getCollection();
  }, []);

  return (
    <div id="collections">
      <div className="hero is-large">
        <h1 className="subtitle has-text-centered">{currentCategory?.title}</h1>
        <div className="hero-inner" style={{ marginTop: "0.25rem" }}>
          <Collection
            addToCart={props.addToCart}
            className={"collection-container"}
            data={data}
          />
        </div>
      </div>
    </div>
  );
};
