import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  lazy,
  Suspense,
} from "react";
import AddToCartFlipper from "./AddToCartFlipper";
import ProgressiveImage from "react-progressive-image";
import useScreen from "../../hooks/useScreen";
const Video = lazy(() => import("../Video/Video"));

export default (props) => {
  const { title, pictures, url, available_products } = props.data;
  const [isFlipped, setIsFlipped] = useState(false);
  const [flipperEnabled, setIsFlipperEnabled] = useState(false);
  const { width } = useScreen();

  const flipOut = useCallback(() => {
    if (!flipperEnabled) return;
    setIsFlipped(false);
  }, [setIsFlipped, flipperEnabled]);

  const flipIn = useCallback(() => {
    if (!flipperEnabled) return;
    setIsFlipped(true);
  }, [setIsFlipped, flipperEnabled]);

  const rand = React.useMemo(
    () => Math.floor(1000000 + Math.random() * 9000000),
    []
  );

  useEffect(() => {
    const placeholderImage = document.getElementById("placeholder-" + rand);

    if (placeholderImage) {
      placeholderImage.onload = () => {
        setIsFlipperEnabled(true);
      };
    }
    setTimeout(() => {
      setIsFlipperEnabled(true);
    }, 1500);
  }, [
    rand,
    setIsFlipperEnabled,
    document.getElementById("placeholder-" + rand),
  ]);

  const redirect = useCallback(() => {
    window.location = "/product/" + url;
  });

  const secondaryPreview = useMemo(
    () => (
      <div
        style={{
          display: isFlipped ? "block" : "none",
        }}
        className="secondary-preview-container"
        onMouseEnter={() => flipIn()}
        onMouseOut={() => flipOut()}
        onMouseLeave={() => flipOut()}
      >
        {!pictures[1]?.toLowerCase().includes(".jpg") ? (
          <Suspense fallback={null}>
            <Video
              className="image"
              aspectRatio={"2:3"}
              onClick={() => redirect()}
              src={pictures[1]}
            ></Video>
          </Suspense>
        ) : (
          <img
            onClick={() => {
              redirect();
            }}
            className="image"
            src={pictures[1]}
            style={{
              width: "100%",
            }}
          />
        )}
      </div>
    ),
    [
      pictures[1],
      pictures[1]?.toLowerCase().includes(".jpg"),
      flipOut,
      flipIn,
      isFlipped,
    ]
  );

  const picPreview = useMemo(
    () => (
      <ProgressiveImage
        className="main-image"
        src={pictures[0]}
        placeholder={
          pictures[0]?.slice(0, pictures[0].length - 4) + "-small.jpg"
        }
      >
        {(src) => (
          <img
            onMouseEnter={() => width > 1024 && flipIn()}
            onMouseLeave={() => flipOut()}
            onMouseOut={() => flipOut()}
            onClick={() => {
              redirect();
            }}
            className="image"
            id={"placeholder-" + rand}
            src={src}
            style={{
              width: "100%",
              display: !isFlipped ? "block" : "none",
            }}
            alt="an image"
          />
        )}
      </ProgressiveImage>
    ),
    [pictures[0], pictures[1], isFlipped, rand, flipOut, flipIn, width]
  );

  return (
    <div className="collection-item-wrapper">
      <div
        id={"pre-load-placeholder-" + rand}
        className="pre-load-placeholder"
      ></div>
      {secondaryPreview}
      {picPreview}
      <div
        className="data-container"
        onClick={() => {
          redirect();
        }}
      >
        <div>
          <h2 className="item-title">{title}</h2>
        </div>
        {!props.hidePrice && (
          <h2 className="item-title-price">${available_products[0].price}</h2>
        )}
      </div>
      {!props.disableAddToCart && (
        <AddToCartFlipper data={props.data} addToCart={props.addToCart} />
      )}
    </div>
  );
};
