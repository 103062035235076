import React from "react";
import SizingInfo from "./SizingInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default ({ isActive, setModalVisible }) => {
  return (
    <div className={`modal ${isActive ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onClick={() => {
          setModalVisible(false);
        }}
      ></div>
      <div
        className="modal-content"
        style={{ maxHeight: "calc(100vh - 50px)" }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 100,
            height: 30,
            width: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => setModalVisible(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="box" style={{ padding: "2rem 0.5rem" }}>
          <SizingInfo />
          <div className="buttons is-centered">
            <button
              className="button is-primary"
              onClick={() => setModalVisible(false)}
            >
              Continue Shopping
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
