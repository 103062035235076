import React, { useEffect, useState } from "react";
import ExistingItemsEditing from "../components/Admin/ExistingItemsEditing";
import NewItemForm from "../components/Admin/NewItemForm";
import PlacedOrders from "../components/Admin/PlacedOrders";
import InfoEditor from "../components/Admin/InfoEditor";
import Promotions from "../components/Admin/Promotions";
import Subscriptions from "../components/Admin/Subscriptions";
import Categories from "../components/Admin/Categories";
import axios from "axios";
import NewsletterManager from "../components/Admin/NewsletterManager";
import NonEmptyCarts from "../components/Admin/NonEmptyCarts";

export default (props) => {
  const availablePages = [
    "createNew",
    "editExisting",
    "orders",
    "info",
    "promotions",
    "subscriptions",
    "categories",
    "newsletter",
    "nonEmptyCarts",
  ];
  const [currentSelection, setCurrentSelection] = useState("createNew");
  const [existingItems, setExistingItems] = useState([]);

  const getExistingItems = async () => {
    let existing = await axios
      .get("/admin/api/existingItems")
      .then((res) => res.data);
    setExistingItems(existing);
  };

  useEffect(() => {
    let requestedSubPage = props.location.search.slice(
      1,
      props.location.search.length
    );
    if (
      availablePages.includes(requestedSubPage) &&
      currentSelection !== requestedSubPage
    ) {
      setCurrentSelection(requestedSubPage);
    }
    if (requestedSubPage == "editExisting") {
      getExistingItems();
    }
  }, []);

  return (
    <div id="admin" className="hero is-bold is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div
              className={`column admin-page-selector ${
                currentSelection === "createNew" ? "is-active" : ""
              }`}
            >
              <a
                onClick={() => {
                  setCurrentSelection("createNew");
                  window.location.search = "createNew";
                }}
              >
                Create New
              </a>
            </div>
            <div
              className={`column admin-page-selector ${
                currentSelection === "editExisting" ? "is-active" : ""
              }`}
            >
              <a
                onClick={() => {
                  setCurrentSelection("editExisting");
                  window.location.search = "editExisting";
                }}
              >
                Existing Items
              </a>
            </div>
            <div
              className={`column admin-page-selector ${
                currentSelection === "orders" ? "is-active" : ""
              }`}
            >
              <a
                onClick={() => {
                  setCurrentSelection("orders");
                  window.location.search = "orders";
                }}
              >
                Orders
              </a>
            </div>
            <div
              className={`column admin-page-selector ${
                currentSelection === "info" ? "is-active" : ""
              }`}
            >
              <a
                onClick={() => {
                  setCurrentSelection("info");
                  window.location.search = "info";
                }}
              >
                Info
              </a>
            </div>
            <div
              className={`column admin-page-selector ${
                currentSelection === "promotions" ? "is-active" : ""
              }`}
            >
              <a
                onClick={() => {
                  setCurrentSelection("promotions");
                  window.location.search = "promotions";
                }}
              >
                Promotions
              </a>
            </div>
            <div
              className={`column admin-page-selector ${
                currentSelection === "subscriptions" ? "is-active" : ""
              }`}
            >
              <a
                onClick={() => {
                  setCurrentSelection("subscriptions");
                  window.location.search = "subscriptions";
                }}
              >
                Subscriptions
              </a>
            </div>
            <div
              className={`column admin-page-selector ${
                currentSelection === "categories" ? "is-active" : ""
              }`}
            >
              <a
                onClick={() => {
                  setCurrentSelection("categories");
                  window.location.search = "categories";
                }}
              >
                Categories
              </a>
            </div>
            <div
              className={`column admin-page-selector ${
                currentSelection === "newsletter" ? "is-active" : ""
              }`}
            >
              <a
                onClick={() => {
                  setCurrentSelection("newsletter");
                  window.location.search = "newsletter";
                }}
              >
                Newsletter
              </a>
            </div>
            <div
              className={`column admin-page-selector ${
                currentSelection === "nonEmptyCarts" ? "is-active" : ""
              }`}
            >
              <a
                onClick={() => {
                  setCurrentSelection("nonEmptyCarts");
                  window.location.search = "nonEmptyCarts";
                }}
              >
                Non-Empty Carts
              </a>
            </div>
          </div>
          {currentSelection === "createNew" ? (
            <NewItemForm updateItems={getExistingItems} />
          ) : currentSelection === "editExisting" ? (
            <ExistingItemsEditing
              existingItems={existingItems}
              getExistingItems={getExistingItems}
            />
          ) : currentSelection === "orders" ? (
            <PlacedOrders />
          ) : currentSelection === "info" ? (
            <InfoEditor />
          ) : currentSelection === "promotions" ? (
            <Promotions />
          ) : currentSelection === "subscriptions" ? (
            <Subscriptions />
          ) : currentSelection === "categories" ? (
            <Categories />
          ) : currentSelection === "newsletter" ? (
            <NewsletterManager />
          ) : currentSelection === "nonEmptyCarts" ? (
            <NonEmptyCarts />
          ) : null}
        </div>
      </div>
    </div>
  );
};
