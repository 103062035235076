import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { JsonToTable } from "react-json-to-table";

export default (props) => {
    const [data, setData] = useState([])

    useEffect(()=>{
      axios.get('/admin/api/getAllSubscriptions').then(res => setData(res.data))
    }, [])

    return (
        <div id='promo-admin' className='promo-editor'>
          { 
            data.map(el => 
              <div className='title editable-promo'>
                <p>{el.email}</p> 
                {(el.requestedNotifications.length ? el.requestedNotifications.map(rq => <JsonToTable json={rq} />) : null)}
              </div>)
          }
        </div>
    )

}
