import React from "react";
import Field from "./Field";

export default (props) => {
    const {
        shippingInfo,
        back,
        stage,
        shippingType,
        shippingFee,
        backToShipping,
    } = props;
    const { email, address, address2, country, city, state, zip } =
        shippingInfo;
    return (
        <div className='content-box' style={{ 
            marginTop: "2rem", 
            paddingTop: 0,
            paddingBottom: 0 
        }}>
            <div className='content contact-info'>
                <p className='info'>Contact</p>
                <p className='value'>{email}</p>
                <a href='#' onClick={() => back()}>
                    Change
                </a>
            </div>
            <div className='content address'>
                <p className='info'>Ship to</p>
                <p className='value'>{`
                    ${address ? address + ',' : ''} 
                    ${address2 ? address2 + ',' : ''} 
                    ${city ? city + ',' : ''} 
                    ${state ? state + ',' : ''} 
                    ${zip ? zip + ',' : ''} 
                    ${country || ''}`
                }</p>
                <a href='#' onClick={() => back()}>
                    Change
                </a>
            </div>
            {stage === "billing" && (
                <div className='content address'>
                    <p className='info'>Method</p>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "calc(100% - 5rem)",
                            justifyContent: "space-between",
                        }}>
                        <p className='value'>{`${shippingType}`}</p>
                        <p style={{ opacity: 0.5, paddingRight: "0.5rem" }}>
                            {shippingFee ? "$" + shippingFee : "free"}
                        </p>
                    </div>
                    <a href='#' onClick={() => backToShipping()}>
                        Change
                    </a>
                </div>
            )}
        </div>
    );
};
