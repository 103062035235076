import React, { useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";

export default (props) => {
  let { title, url, size, available_products, color, product_type, quantity } =
    props.data;
  const [selectedColor, setSelectedColor] = useState(null);
  const [mainPicture, setMainPicture] = useState(props.data.pictures[0]);
  const removeFromCart = async (index) => {
    await axios.post("/removeFromCart", { index });
    props.updateCart();
  };

  const product = available_products.find((el) => el.type == product_type);

  useEffect(() => {
    let option = product.colors.find((el) => el.color_name === color);
    setSelectedColor(option);
    if (option.color_picture) {
      setMainPicture(option.color_picture);
    }
  }, []);

  return (
    <div className="cart-elem">
      <div
        className="cart-list-title"
        onClick={() => {
          window.location = "/product/" + url;
        }}
      ></div>
      <div className="cart-list-body">
        <div style={{ width: 50 }}>
          <div className="quantity-circle">{quantity}</div>
          <img
            onClick={() => {
              window.location = "/product/" + url;
            }}
            src={mainPicture.slice(0, mainPicture.length - 4) + "-preview.jpg"}
            className="picture"
          />
        </div>
        <div style={{ width: "60%" }}>
          <strong>{title.split(" ").map(_.capitalize).join(" ")}</strong>
          <div className="color-container">
            {color} / {size}
          </div>
        </div>
        <div className="price">${product.price * quantity}</div>
      </div>
    </div>
  );
};
