import React, { useEffect, useState } from 'react';

export default (first) => {
  const [width, setWidth] = useState(0)
  
  const handleResize = (e) => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    setWidth(window.innerWidth)
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize, true);
    }
  }, [])
  
  return { width }
};
