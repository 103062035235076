import React, { useState, useEffect, Fragment } from 'react'
import AuthPage from '../../pages/AuthPage'
import axios from 'axios'

export default (ComponentToProtect) => {
    return (props) => {
        const [loading, setLoading] = useState(true)
        const [redirect, setRedirect] = useState(false)

        const checkAuth = async () => {
            let auth = await axios.get(`/checkToken`)
            if (!auth.data) {
                setRedirect(true)
                setLoading(false)
                return
            } else {
                setRedirect(false)
                setLoading(false)
            }
        }
        
        useEffect(()=>{
            checkAuth()
        }, [])

        const update = () => {
            checkAuth()
        }

        if (loading) return null
        return (
            redirect
            ? <AuthPage update={update}/>
            : <Fragment>
                <ComponentToProtect {...props}/>
              </Fragment>
        )
    }
}