import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

export default (props) => {
    let { title, url, size, available_products, color, product_type } =
        props.data;
    const [selectedColor, setSelectedColor] = useState(null);
    const [mainPicture, setMainPicture] = useState(props.data.pictures[0]);
    const removeFromCart = async (index) => {
        await axios.post("/removeFromCart", { index });
        props.updateCart();
    };

    const product = available_products.find((el) => el.type == product_type);

    useEffect(() => {
        let option = product.colors.find((el) => el.color_name === color);
        setSelectedColor(option);
        if (option.color_picture) {
            setMainPicture(option.color_picture);
        }
    }, []);

    return (
        <div className='cart-elem'>
            <div
                className='cart-list-title'
                onClick={() => {
                    window.location = "/product/" + url;
                }}>
                <h1>
                    {product_type} - {title}
                </h1>
            </div>
            <div className='cart-list-body'>
                <p
                    className='remove'
                    style={{ opacity: 0.7 }}
                    onClick={() => {
                        removeFromCart(props.index);
                    }}>
                    Remove
                </p>
                <img
                    onClick={() => {
                        window.location = "/product/" + url;
                    }}
                    src={
                        mainPicture.slice(0, mainPicture.length - 4) +
                        "-preview.jpg"
                    }
                    width={"15%"}
                    className='picture'
                />
                <div className='size'>size: {size}</div>
                <div className='color-container'>
                    {selectedColor ? (
                        <div
                            className='color-sample'
                            style={{
                                backgroundColor: selectedColor.color_code,
                            }}></div>
                    ) : null}
                    {color}
                </div>
                <div className='price'>${product.price}</div>
            </div>
        </div>
    );
};
