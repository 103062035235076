import React from 'react';
import ProgressiveImage from 'react-progressive-image'

export default (props) => {
    const {img_1, img_2, subtitle} = props.data
    return (  
        <div className='tall-with-small-overlay-text'>
            <ProgressiveImage 
                className='main-image' 
                src={img_1} 
                placeholder={img_1.slice(0, img_1.length - 4) + '-small.jpg'}
            > 
                {src => <img className='img-1' src={src} alt="an image" />} 
            </ProgressiveImage> 
            <ProgressiveImage 
                className='main-image' 
                src={img_2} 
                placeholder={img_2.slice(0, img_2.length - 4) + '-small.jpg'}
            > 
                {src => <img className='img-2' src={src} alt="an image" />} 
            </ProgressiveImage> 
            <h2 className='subtitle'>{subtitle}</h2>
        </div>
       
    );
}

