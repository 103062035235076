import React, { useState } from 'react';
import components from '../ProductLandingContent/components'
import DataForm from './DataForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default (props) => {
    const [formType, setFormType] = useState('')

    return (
        <div id='newTileForm'>
           <h1 className='title'>Add New Tile</h1>
           <div className='tile-types-container'>
               { formType === ''
                    ?  Object.keys(components).map(key => <div className='tile-type-option' onClick={()=>{ setFormType(key) }}>{components[key].name}</div>)
                    : <div>
                        <a href='#' onClick={()=>{ setFormType('') }}><FontAwesomeIcon icon={faArrowLeft}/>back</a>
                        <DataForm submit={props.submit} formType={formType} />
                    </div>
               }
           </div>
        </div>
    )
}