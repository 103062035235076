import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

const LeftNavModal = ({ categories, setModalVisible, modalVisible }) => {
  return (
    <div className={`modal left-nav-modal ${modalVisible ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onClick={() => {
          setModalVisible(false);
        }}
      ></div>
      <div className="modal-content cart-modal-content fullheight-overlay slide-left">
        <div
          style={{
            position: "absolute",
            top: 10,
            left: 15,
            zIndex: 100,
            height: 30,
            width: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => setModalVisible(false)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <div className="left-nav-modal-box">
          <div className="padded-header-guide">Categories</div>
          {categories?.map((cat) => (
            <div className="navbar-item ">
              <a href={`/categories/?category=${cat.key}`}>{cat.title}</a>
            </div>
          ))}
          <div className="navbar-item ">
            <a href={`/collections`}>Shop all</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftNavModal;
