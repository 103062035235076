import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faSearch } from "@fortawesome/free-solid-svg-icons";

export default (props) => {
  const [orders, setOrders] = useState([]);
  const [showTrackingModal, setShowTrackingModal] = useState(false);
  const [newTrackingNumber, setNewTrackingNumber] = useState("");
  const [newTrackingUrl, setNewTrackingUrl] = useState("");
  const [modalOrderNumber, setModalOrderNumber] = useState("");
  const [searchString, setSearchString] = useState("");
  const [toRender, setToRender] = useState([]);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [selectedShippingOption, setSelectedShippingOption] = useState(null);

  const isCustomTrackingEnabled = useMemo(
    () => selectedShippingOption === null,
    [selectedShippingOption]
  );

  const getOrders = async () => {
    let existingOrders = await axios
      .get("/admin/api/getPlacedOrders")
      .then((res) => res.data);
    setOrders(existingOrders);
    if (searchString === "") {
      setToRender(existingOrders.slice(0, 10));
    } else {
      let filtered = existingOrders.filter(
        (e) =>
          e.id.toFixed(0).includes(searchString.toLowerCase()) ||
          e.shippingInfo.lastName
            .toLowerCase()
            .includes(searchString.toLowerCase())
      );
      setToRender(filtered.slice(0, 10));
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  const updateSearch = (value) => {
    setSearchString(value);
    if (value !== "") {
      let filtered = orders.filter(
        (e) =>
          e.id.toFixed(0).includes(value.toLowerCase()) ||
          e.shippingInfo.lastName.toLowerCase().includes(value.toLowerCase())
      );
      setToRender(filtered.slice(0, 10));
    } else {
      setToRender(orders.slice(0, 10));
    }
  };

  const CartContents = (props) => {
    const getPricePerPick = (cartPick) => {
      return Number(
        cartPick.available_products?.find(
          (el) => el.type == cartPick.product_type
        ).price
      );
    };

    return props.cart.map((item) => {
      if (!item) {
        return "failed to load item";
      }
      return (
        <div class="box">
          <article class="media">
            <div class="media-left">
              <figure class="image is-64x64">
                <img src={item.pictures?.[0] || ""} alt="Image" />
              </figure>
            </div>
            <div class="media-content">
              <div class="content">
                <p>
                  <strong>{item.title}</strong> <br />{" "}
                  <small>
                    Type:{" "}
                    <span className="tag is-info">{item.product_type}</span>{" "}
                  </small>{" "}
                  <small>
                    Size: <span className="tag is-info">{item.size}</span>
                  </small>{" "}
                  <small>
                    Color: <span className="tag is-info">{item.color}</span>
                  </small>
                  <br />
                  <small>
                    Price:{" "}
                    <span className="tag is-success">
                      {getPricePerPick(item)}
                    </span>
                  </small>
                </p>
              </div>
            </div>
          </article>
        </div>
      );
    });
  };

  const ReturnRequest = ({ returnRequest }) => {
    return (
      <>
        <h1 className="title">RETURN REQUESTED</h1>
        <strong>
          Refund to:{" "}
          {returnRequest.creditSelected
            ? "STORE CREDIT"
            : "ORIGINAL PAYMENT METHOD"}
        </strong>
        {returnRequest.items.map((item) => {
          return (
            <div className="order-cart-container">
              <p>{item.product_type}</p>
              <p>{item.title}</p>
              <p>Size: {item.size}</p>
              <p>Color: {item.color}</p>
            </div>
          );
        })}
        <p>Subtotal: {returnRequest.subtotal}</p>
        <p>Discount: -{returnRequest.discount}</p>
        <p>Tax: {returnRequest.tax}</p>
        <p>Total refund: {returnRequest.total_refund}</p>

        <strong>
          <a href={returnRequest.tracking_url_provider}>
            Track shipment: {returnRequest.tracking_number}
          </a>
        </strong>
      </>
    );
  };

  const getShippingOptions = async (orderId) => {
    setShippingOptions([]);
    const { data } = await axios.post("/admin/api/getShippingOptions", {
      orderId,
    });
    setShippingOptions(data);
  };

  const markAsProcessed = async (orderId, rateId) => {
    const response = await axios.post("/admin/api/markAsProcessed", {
      orderId,
      rateId,
    });
    if (response.data.status === "ERROR") {
      alert(response.data.message[0].text);
      return;
    }
    setShowTrackingModal(false);
    setNewTrackingNumber("");
    getOrders();
  };

  const assignCustomTracking = async (
    orderId,
    trackingNumber,
    trackingUrlLink
  ) => {
    await axios.post("/admin/api/assignCustomTracking", {
      orderId,
      trackingNumber,
      trackingUrlLink,
    });
    setShowTrackingModal(false);
    setNewTrackingNumber("");
    setNewTrackingUrl("");
    getOrders();
  };

  return (
    <div className="admin">
      <h1 className="title">Placed Orders</h1>
      <div className="field">
        <p className="control has-icons-left">
          <input
            placeholder="search"
            className="input is-rounded"
            value={searchString}
            onChange={(e) => updateSearch(e.target.value)}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </p>
      </div>
      <div className={`modal ${showTrackingModal ? "is-active" : ""}`}>
        <div
          className="modal-background"
          onClick={() => setShowTrackingModal(false)}
        ></div>
        <div className="modal-content">
          <div className="box">
            <h1 className="title">
              Assign shipping for order #{modalOrderNumber}
            </h1>
            <section class="section">
              <h2 className="subtitle">
                Select shipping option to buy shipping label
              </h2>
              {!shippingOptions?.length && (
                <FontAwesomeIcon className="fa-spin fa-2xl" icon={faSpinner} />
              )}
              <div className="shipping-options-container">
                {shippingOptions?.length &&
                  shippingOptions.map((option, index) => (
                    <div
                      onClick={() => {
                        index === selectedShippingOption
                          ? setSelectedShippingOption(null)
                          : setSelectedShippingOption(index);
                      }}
                      className={`shipping-option ${
                        index === selectedShippingOption ? "is-selected" : ""
                      } `}
                    >
                      <img
                        width={75}
                        height={75}
                        style={{
                          objectFit: "cover",
                        }}
                        src={option.provider_image_75}
                      />
                      <h2>{option.servicelevel.name}</h2>
                      <h2>
                        {option.provider} - ${option.amount}
                      </h2>
                    </div>
                  ))}
              </div>
              <button
                className="button is-success right"
                disabled={selectedShippingOption === null}
                style={{ float: "right" }}
                onClick={() =>
                  markAsProcessed(
                    modalOrderNumber,
                    shippingOptions[selectedShippingOption].object_id
                  )
                }
              >
                Buy shipping label and assign to order
              </button>
            </section>
            <section class="section">
              <h2 className="subtitle">Or assign custom tracking number</h2>
              <div style={{ width: "100%" }}>
                <input
                  className="input"
                  placeholder="tracking number"
                  value={newTrackingNumber}
                  onChange={(e) => setNewTrackingNumber(e.target.value)}
                />
                <input
                  className="input"
                  placeholder="tracking link"
                  value={newTrackingUrl}
                  onChange={(e) => setNewTrackingUrl(e.target.value)}
                />
                <button
                  className="button is-success"
                  style={{
                    float: "right",
                  }}
                  disabled={!isCustomTrackingEnabled}
                  onClick={() =>
                    assignCustomTracking(
                      modalOrderNumber,
                      newTrackingNumber,
                      newTrackingUrl
                    )
                  }
                >
                  Assign a custom tracking number
                </button>
              </div>
            </section>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setShowTrackingModal(false)}
        ></button>
      </div>
      {orders.length > 0
        ? toRender.map((order) => {
            const {
              firstName,
              lastName,
              address,
              address2,
              email,
              city,
              state,
              zip,
              phone,
              country,
              shippingType,
              paymentMethodDetails,
            } = order.shippingInfo;

            return (
              <div
                className={`order-container ${
                  order.processed
                    ? order.returnRequest
                      ? "is-return"
                      : "is-processed"
                    : ""
                }`}
              >
                <div className="columns">
                  <div className="column">
                    <h1 className="title">Order #: {order.id}</h1>
                    <CartContents cart={order.cart} />
                    <strong>Cart summary</strong>
                    {order?.promo?.code && (
                      <small>
                        <br /> Promo:{" "}
                        <span className="tag is-info">
                          {order.promo.code} {"("}{" "}
                          {order.promo.type &&
                          order.promo.type == "dollarAmount"
                            ? "$" + order.promo.discount
                            : order.promo.discount * 100 - 100 + "%"}{" "}
                          {")"}{" "}
                        </span>
                      </small>
                    )}
                    {order?.locals && (
                      <>
                        <small>
                          <br /> Subtotal: ${order?.locals.subtotal}
                          <br /> Shipping fee: ${order?.locals.shippingFee}
                          <br /> Tax: ${order?.locals.tax}
                          <strong>
                            {" "}
                            <br /> Total: ${order?.locals.total}{" "}
                          </strong>
                        </small>
                      </>
                    )}
                    {order.returnRequest && (
                      <ReturnRequest returnRequest={order.returnRequest} />
                    )}
                  </div>
                  <div className="column is-one-third">
                    <div
                      className={`name-wrapper ${
                        order.processed ? "processed" : ""
                      }`}
                    >
                      <h2 className="subtitle">
                        Name: {firstName + " " + lastName}
                      </h2>
                      {!order.processed ? (
                        <button
                          className="button is-success"
                          onClick={() => {
                            setModalOrderNumber(order.id);
                            getShippingOptions(order.id);
                            setShowTrackingModal(true);
                          }}
                        >
                          Assign Tracking Number
                        </button>
                      ) : null}
                    </div>
                    <h2
                      className="subtitle"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Email: {email}
                    </h2>
                    <h2 className="subtitle">Phone: {phone}</h2>
                    <h2 className="subtitle">
                      Address:{" "}
                      {address +
                        ", " +
                        address2 +
                        ", " +
                        city +
                        ", " +
                        state +
                        ", " +
                        zip +
                        ", " +
                        country}
                    </h2>
                    <h2 className="subtitle">Shipping type: {shippingType}</h2>
                    <h2 className="subtitle">
                      Payment method: {paymentMethodDetails?.paymentMethod}
                    </h2>
                    <h2 className="subtitle">
                      Billing:{` ${JSON.stringify(order.billingInfo)}`}
                    </h2>
                    {paymentMethodDetails?.detail && (
                      <h3>Payment details: {paymentMethodDetails?.detail}</h3>
                    )}
                    {order.processed && (
                      <h2 className="subtitle">
                        Tracking: {order.trackingNumber}
                      </h2>
                    )}
                    {order.processed && (
                      <a
                        className="button is-success"
                        href={order.trackingInfo?.label_url}
                        target="_blank"
                      >
                        Download shipping label
                      </a>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};
