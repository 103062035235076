import React, { Component } from 'react';
import ProgressiveImage from 'react-progressive-image'

export default (props) => {
    const {img_1, img_2} = props.data
    return (  
        <div className='pic-layout-2'>
             <div className='columns'>
                <div className='column'>
                    <ProgressiveImage 
                        src={img_1} 
                        placeholder={img_1.slice(0, img_1.length - 4) + '-small.jpg'}
                    > 
                        {src => <img src={src} style={{width:'100%'}} alt="an image" />} 
                    </ProgressiveImage> 
                </div>
                <div className='column'>
                    <ProgressiveImage 
                        src={img_2} 
                        placeholder={img_2.slice(0, img_2.length - 4) + '-small.jpg'}
                    > 
                        {src => <img src={src} style={{width:'100%'}} alt="an image" />} 
                    </ProgressiveImage> 
                </div>
            </div>
        </div>
       
    );
}