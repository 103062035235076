import React, { useEffect } from "react";
import "./styles/sass/App.sass";
import { Route, Switch } from "react-router-dom";
import AdminPage from "./pages/AdminPage";
import axios from "axios";
import CheckoutStepsPage from "./pages/CheckoutStepsPage";
import CollectionsPage from "./pages/CollectionsPage";
import CategoriesPage from "./pages/CategoriesPage";
import EditLandingPage from "./pages/EditLandingPage";
import HomePage from "./pages/HomePage";
import InfoPage from "./pages/InfoPage";
import ProductPage from "./pages/ProductPage";
import StoryPage from "./pages/StoryPage";
import WithAuth from "./components/Navigation/withAuth";
import WithNavigation from "./components/Navigation/WithNavigation";
import ReturnFormPage from "./pages/ReturnFormPage";
import PromoPerformance from "./pages/PromoPerformance";
import UnsubscribePage from "./pages/UnsubscribePage";
import SizingGuidePage from "./pages/SizingGuidePage";
import LogRocket from "logrocket";

axios.defaults.withCredentials = true;

LogRocket.init("1dmpdi/murashki");

export default () => {
  const issueToken = async () => {
    if (document.cookie == "") {
      localStorage.setItem("promoTimeout", Date.now() + 60000);
    }
    await axios.get(`/token`, { withCredentials: true });
  };

  useEffect(() => {
    issueToken();
  }, []);

  return (
    <div>
      <Switch>
        <Route exact path="/" component={WithNavigation(HomePage)} />
        <Route path="/product/*" component={WithNavigation(ProductPage)} />
        <Route
          exact
          path="/collections"
          component={WithNavigation(CollectionsPage)}
        />
        <Route
          exact
          path="/categories/*"
          component={WithNavigation(CategoriesPage)}
        />
        <Route
          path="/cart/*"
          component={WithNavigation(CheckoutStepsPage, true)}
        />
        <Route
          path="/unsubscribe/*"
          component={WithNavigation(UnsubscribePage, true)}
        />

        <Route exact path="/info/*" component={WithNavigation(InfoPage)} />
        <Route exact path="/story" component={WithNavigation(StoryPage)} />
        <Route
          exact
          path="/sizing-guide"
          component={WithNavigation(SizingGuidePage)}
        />
        <Route
          exact
          path="/return-form"
          component={WithNavigation(ReturnFormPage)}
        />
        <Route
          exact
          path="/promo-performance"
          component={WithNavigation(PromoPerformance)}
        />

        {/* //bulk */}
        <Route
          exact
          path="/bulk/admin"
          component={WithAuth(WithNavigation(AdminPage))}
        />
        <Route
          path="/edit/landing/*"
          component={WithAuth(WithNavigation(EditLandingPage))}
        />

        <Route path="*" component={WithNavigation(HomePage)} />
      </Switch>
    </div>
  );
};
